// Dependencies
import React, { useState, useEffect, Fragment } from 'react'
import Helpers from '../Helpers.js'
import { connect } from 'react-redux'

// Components
import { ZoomSlider } from '@anishp16/lazarus-cds'

// Images
import grid from '../images/grid.svg'
import sideBySide from '../images/sideBySide.svg'
import x from '../images/white/x.svg'

// Styles
import '../styles/Gallery.css'
import '@anishp16/lazarus-cds/dist/index.css'

function Gallery(props) {
  const [zoomValue, setZoomValue] = useState(50)
  const [yearArr, setYearArr] = useState([])
  const [isGridView, setIsGridView] = useState(false)
  const [isCompareView, setIsCompareView] = useState(false)
  const [testsSortedByDate, setTestsSortedByDate] = useState({})
  const [selectedImages, setSelectedImages] = useState([])
  const [hasToolTipShown, setHasToolTipShown] = useState(false)
  // Necessary to change state after selectedImages is updated
  const [timeStamp, setTimeStamp] = useState(0)
  const tests = props.currentTest ? props.currentTest.Tests : []

  const onChangeZoom = (event) => {
    setZoomValue(event.target.value)
  }

  const imageHeight = () => {
    const body = document.getElementById('gallery-body')
    const bodyHeight = .80 * body.clientHeight
    //.80 seems to keep full picture on screen
    const zoomRatio = zoomValue / 100
    const minRatio = .1
    const zoomFactor = zoomRatio > minRatio ? zoomRatio : minRatio
    return bodyHeight * zoomFactor
  }

  const onClickImage = (test) => {
    const indexOfTest = selectedImages.indexOf(test)
    const indexOfNull = selectedImages.indexOf(null)
    const newArr = selectedImages
    if (indexOfTest === -1) {
      // Image is not selected
      if (indexOfNull === -1) {
        // No image was unselected
        if (selectedImages.length < 2) {
          // Fewer than 2 images selected
          selectedImages.push(test)
        } else {
          // 2 images already selected
          selectedImages[0] = selectedImages[1]
          selectedImages[1] = test
        }
      } else {
        // Image was unselected
        newArr[indexOfNull] = test
      }
    } else {
      // Image is selected
      newArr[indexOfTest] = null
    }
    setSelectedImages(newArr)
    setTimeStamp(Date.now())
    // if (newArr.length == 2 && newArr.indexOf(null) === -1) {
    //   // 2 Images are selected. Switch to comparison view
    //   setIsCompareView(true)
    // }
  }

  const sortTestsByDate = (tests) => {
    const dateObj = {}
    tests.forEach((test) => {
      const dateTaken = test.DateTaken
      const year = Helpers.timeStampToYear(dateTaken)
      const month = Helpers.timeStampToMonth(dateTaken)
      if (dateObj[year]) {
        if (dateObj[year][month]) {
          dateObj[year][month].push(test)
        } else {
          dateObj[year][month] = [test]
        }
      } else {
        dateObj[year] = {
          [month]: [test],
        }
      }
    })
    setTestsSortedByDate(dateObj)
    setYearArr(Object.keys(dateObj))
  }

  useEffect(() => {
    if (props.currentTest) {
      sortTestsByDate(tests)
    }
  }, [props.currentTest])

  const setSideBySideTests = () => {
    const sideBySideTests = [...selectedImages]
    const lastTest = tests[tests.length - 1]
    if (!sideBySideTests[0]) {
      sideBySideTests[0] = tests[0]
      sideBySideTests[0] = tests[0]
    }
    if (!sideBySideTests[1]) {
      if (lastTest && lastTest !== sideBySideTests[0]) {
        sideBySideTests[1] = lastTest
        sideBySideTests[1] = lastTest
      }
    }
    setSelectedImages(sideBySideTests)
  }

  const thumbnailRender = (selectionIndex) => {
    const lastImageIndex = tests.length - 1 >= 0 ? tests.length - 1 : 0
    let onClick
    const newArr = [...selectedImages]
    const isSelected = (test) => {
      return test === selectedImages[selectionIndex]
    }
    if (selectionIndex === 0) {
      onClick = (testIndex) => {
        newArr[0] = tests[testIndex]
        setSelectedImages(newArr)
      }
    } else {
      onClick = (testIndex) => {
        newArr[1] = tests[testIndex]
        setSelectedImages(newArr)
      }
    }
    return (
      <Fragment>
        <div className='first-third'>
          <img
            src={tests[0].ImageRef}
            onClick={() => onClick(0)}
            className={isSelected(tests[0]) ? 'selected-image' : undefined}
          />
        </div>
        <div className='second-third'>
          <div>
            {tests.map((test, j) => {
              if (j > 0 && j < tests.length - 1) {
                return (
                  <img
                    src={test.ImageRef}
                    onClick={() => onClick(j)}
                    className={isSelected(test) ? 'selected-image' : undefined}
                  />
                )
              }
            })}
          </div>
        </div>
        <div className='third-third'>
          {tests[0] !== tests[lastImageIndex] &&
            <img
              src={tests[lastImageIndex].ImageRef}
              onClick={() => onClick(lastImageIndex)}
              className={isSelected(tests[lastImageIndex]) ? 'selected-image' : undefined}
            />
          }
        </div>
      </Fragment>
    )
  }

  const compareViewRender = (
    <div className='compare-view'>
      <div className='side-by-side-image-container'>
        {selectedImages.map((test, i) => {
          if (test) {
            return (
              <div key={i}>
                <img src={test.ImageRef} />
                <p>{Helpers.timeStampToDate(test.DateTaken)}</p>
              </div>
            )
          }
        })}
      </div>
      <div className='gallery-thumbnails'>
        <div className='gallery-1'>
          <div className='white-line' />
          {thumbnailRender(0)}
        </div>
        <div className='gallery-2'>
          <div className='white-line' />
          {thumbnailRender(1)}
        </div>
      </div>
    </div>
  )

  return (
    <div className='gallery-container'>
      <nav className='top-nav'>
        <div onClick={props.closeGallery} className='x-box'>
          <img src={x} />
        </div>
        {props.currentPatient && props.currentTest &&
          <div className="uri-div">
            <pre>
              {`${props.currentPatient.Name} > Timeline ID: ${props.currentTest.Id}`}
            </pre>
          </div>
        }
      </nav>
      <body id='gallery-body'>
        <div className='gallery-side'></div>
        <div className='a'>
          {isCompareView ? (
            compareViewRender
          ) : (
            <Fragment>
              <div className='under-nav'>
                <div className='view-toggle flex'>
                  <span>View:</span>
                  <div
                    onClick={() => setIsGridView(false)}
                    className={
                      !isGridView ? 'timeline-btn toggle-btn selected-btn' :
                        'timeline-btn toggle-btn'
                    }
                  >
                    <p>TIMELINE</p>
                  </div>
                  <div
                    onClick={() => setIsGridView(true)}
                    className={
                      isGridView ? 'timeline-btn grid-btn selected-btn' :
                        'timeline-btn grid-btn'
                    }
                  >
                    <p>GRID</p>
                  </div>
                </div>
                <div className='zoom-slider-container'>
                  <ZoomSlider
                    onChangeZoom={onChangeZoom}
                    value={zoomValue}
                  />
                </div>
              </div>
              <div className='images'>
                <Fragment>
                  {selectedImages.length === 2 && !hasToolTipShown &&
                    <div className='tooltip'>
                      <p>Click for side-by-side comparison</p>
                    </div>
                  }
                  <Fragment>
                    {isGridView ? (
                      <div
                        className='grid-images'
                        style={{
                          gridTemplateColumns: `repeat(auto-fit, ${imageHeight()}px)`,
                        }}
                      >
                        {tests.map((test, i) => {
                          const isSelected = selectedImages.includes(test)
                          const borderStyle = isSelected ? '3px solid white' : undefined
                          return (
                            <div className='grid-image' key={i}>
                              <img
                                onClick={() => onClickImage(test)}
                                style={{
                                  width: `${imageHeight()}px`,
                                  height: 'auto',
                                  border: borderStyle,
                                }}
                                className={isSelected ? 'selected-image' : undefined}
                                src={test.ImageRef}
                              />
                              <p>{Helpers.timeStampToDate(test.DateTaken)}</p>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <Fragment>
                        {yearArr.length && yearArr.map((year) => {
                          const monthArr = Object.keys(testsSortedByDate[year])
                          return (
                            monthArr.map((month, i) => {
                              const testArr = testsSortedByDate[year][month]
                              return (
                                <div key={i} className='month-row'>
                                  <div className='year-month'>
                                    <p>
                                      {`${year} ${month}`}
                                    </p>
                                  </div>
                                  <div className='image-carousel-container'>
                                    <div
                                      className='image-carousel'
                                    // style={{
                                    //   width: `${testArr.length * imageHeight()}px`,
                                    // }}
                                    >
                                      {testArr.map((test, j) => {
                                        const isSelected = selectedImages.includes(test)
                                        return (
                                          <div
                                            key={j}
                                            className='carousel-image'
                                            onClick={() => onClickImage(test)}
                                            style={{
                                              width: `${imageHeight()}px`,
                                              height: 'auto',
                                            }}
                                          >
                                            <img src={test.ImageRef} className={isSelected ? 'selected-image' : undefined} />
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          )
                        })}
                      </Fragment>
                    )}
                  </Fragment>
                </Fragment>
              </div>
            </Fragment>
          )}
        </div>
        <div className='gallery-side gallery-side-right'>
          <div
            className={isCompareView ? undefined : 'selected-view'}
            onClick={() => setIsCompareView(false)}
          >
            <img src={grid} />
          </div>
          <div
            className={isCompareView ? 'selected-view' : undefined}
            onClick={() => {
              setSideBySideTests()
              setHasToolTipShown(true)
              setIsCompareView(true)
            }}
          >
            <img src={sideBySide} />
          </div>
        </div>
      </body>
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  currentTestSet: state.userReducer.currentTestSet,
})

export default connect(mapStateToProps)(Gallery)
