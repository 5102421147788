// Dependencies
import React, {Fragment, useState, useEffect} from 'react'
import firebase from 'firebase/app'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
// import {fetchOrgPatients} from '../actions/fetchOrgPatients'

// Components
import {
  Header, PatientThumb, AsideDiv, FloatingButton, SearchBar,
} from '@anishp16/lazarus-cds'
import TestLink from './TestLink'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Dashboard.css'

// Images
import User from '../images/white/User.svg'
import Add from '../images/white/Add.svg'
import Search from '../images/white/Search.svg'
import XCircle from '../images/white/XCircle.svg'

// Renders Patient list and Aside
function MProc(props) {
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const tabDict = { // Initial tabs state
    'existing patients': false,
    'mproc': false,
    'documentation': false,
    'settings': false,
  }
  const [tabs, setTabs] = useState({tabDict})
  const [searchValue, setSearchValue] = useState(null)
  const [isSearchBarShowing, setIsSearchBarShowing] = useState(false)
  const [isSearchBarRendered, setIsSearchBarRendered] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [patients, setPatients] = useState([])

  // /* Resize main for screen flip */
  // useEffect(() => {
  //   // resize main to fit window, create firebase
  //   const resize = () => {
  //     const windowHeight = window.innerHeight
  //     const windowWidth = window.innerWidth
  //     const mains = document.getElementsByTagName('MAIN')
  //     const main = mains[0]
  //     const em = parseFloat(getComputedStyle(main).fontSize)
  //     // main.style.height = `${windowHeight}px`
  //     main.style.width = `${windowWidth - (12.5 * em)}px`
  //     main.style.height = `${windowHeight - (9 * em)}px`
  //     // width: calc(100% - 12.5em);
  //     // height: calc(100% - 3em);
  //   }
  //   window.addEventListener('resize', resize)
  //   window.addEventListener('orientationchange', resize)
  //   resize()
  // }, [])

  // Set default tab to myPatients, select m-proc tab
  useEffect(() => {
    if (props.subData.status === 'active') {
      onClick('existingPatients')
    } else {
      onClick('remoteTestLink')
    }
    props.storePayload({
      currentPatient: null,
      currentTest: null,
      currentTestSet: null,
      requestSent: false, // Reset to prevent redirect on test request page
      pages: {
        ...props.pagesDict,
        'mproc': true,
      },
    })
    /* For direct login to mproc */
    // if (props.firestore && Object.keys(props.firestore).length && !props.orgPatientList || !props.orgPatientList.length) {
    //   props.fetchOrgPatients(
    //       props.firestore,
    //       props.userData.Membership.Organization,
    //   )
    // }
  }, [])

  /* Wait for userData and patients to render */
  useEffect(() => {
    if (props.userData && props.userData.Info) {
      setPatients(props.patientList)
      setIsLoading(false)
    }
  }, [props.userData, props.patientList])

  function onClick(tab) { // Toggle tab
    if (props.subData.status === 'active' || tab === 'remoteTestLink') {
      setTabs({
        ...tabDict,
        [tab]: true,
      })
    } else {
      props.storePayload({
        userMessage: 'This is a paid feature, please go to settings to set up a subscription plan.',
        notificationType: 3,
        noticationIcon: 'warning',
      })
    }
  }

  /*
    When user hides (or shows) search bar, delete previous search
    value
  */
  useEffect(() => {
    setSearchValue('')
    onChangeSearchBar('')
  }, [isSearchBarShowing])

  const onChangeSearchBar = (str) => {
    setSearchValue(str)
    const searchedPatients = Helpers.filterByName(props.patientList, str)
    setPatients(searchedPatients)
  }

  return (
    <div className='m-proc'>
      {!isLoading && props.userData &&
        <Fragment>
          <Header
            image={props.userData.Info.ImageRef || User}
            name={props.userData.Info.Name}
            practice={props.userData.Info.Practice}
          />
          <main>
            <aside>
              <AsideDiv
                text='Existing Patients'
                number={props.patientList.length}
                isSelected={tabs['existingPatients']}
                type={1} // For large number
                onClick={() => onClick('existingPatients')}
                style={{cursor: 'pointer'}}
              />
              {props.orgData &&
              props.orgData.Permissions[userId]['Send test requests'] &&
              props.orgData.Permissions[userId]['Create patient accounts'] &&
              props.subData.status === 'active' && (
                <Fragment>
                  <Link
                    to='/add'
                    onClick={() => {
                      props.storePayload({ // Clear previous patient
                        patientCreated: false,
                        personImageUrl: null,
                        personImageName: null,
                        newPatient: {},
                      })
                    }}
                  >
                    <AsideDiv
                      text='+ New Patient'
                      number={''}
                      isSelected={tabs['newPatient']}
                      type={1}
                      onClick={() => {
                        onClick('newPatient')
                        props.storeValue('isMprocRequest', true)
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </Link>
                </Fragment>
              )}
              {/* <AsideDiv
                text='Patient Portal Link'
                number={''}
                isSelected={tabs['remoteTestLink']}
                onClick={() => onClick('remoteTestLink')}
                type={1}
                style={{cursor: 'pointer'}}
              /> */}
            </aside>
            {isSearchBarRendered &&
            <div className='searchBar' style={{width: 'calc(100% - 1em'}}>
              <SearchBar
                onChange={(event) => onChangeSearchBar(event.target.value)}
                isShowing={isSearchBarShowing}
                searchValue={searchValue}
              />
            </div>
            }
            {tabs['existingPatients'] &&
            <div className={isSearchBarShowing ? 'patient-list moveDown' :
              isSearchBarRendered ? 'patient-list moveUp' : 'patient-list'}>
              <div className='patients'>
                {patients.map((patient, i) => {
                  if (props.orgData &&
                  props.orgData.Permissions[userId]['Send test requests']) {
                    return (
                      <Link
                        to={`/testRequest/?id=${patient.ID}`}
                        key={i}
                      >
                        <PatientThumb
                          name={patient.Name}
                          image={patient.ImageRef}
                          birthdate={Helpers.formatDate(patient.Birthday)}
                          lastUpdated={Helpers.timeStampToDate(patient.LastUpdate)}
                          newTests={
                            patient.PendingReview && patient.PendingReview.length > 0 ?
                            patient.PendingReview.length :
                            null
                          }
                          isLinked={patient.Source && patient.Source === 'Patient'}
                        />
                      </Link>
                    )
                  } else {
                    return (
                      <div onClick={() => {
                        props.storePayload({
                          userMessage: 'You do not have permission to send test requests',
                          notificationType: 3,
                          notificationIcon: 'warning',
                        })
                      }}>
                        <PatientThumb
                          name={patient.Name}
                          image={patient.ImageRef}
                          birthdate={Helpers.formatDate(patient.Birthday)}
                          lastUpdated={Helpers.timeStampToDate(patient.LastUpdate)}
                          newTests={
                            patient.PendingReview && patient.PendingReview.length > 0 ?
                            patient.PendingReview.length :
                            null
                          }
                          isLinked={patient.Source && patient.Source === 'Patient'}
                        />
                      </div>
                    )
                  }
                })}
              </div>
              <div className='floating-button-container'>
                <FloatingButton
                  onClick={() => {
                    setIsSearchBarShowing(!isSearchBarShowing)
                    setIsSearchBarRendered(true)
                  }}
                  image={isSearchBarShowing ? XCircle : Search}
                  text={isSearchBarShowing ? 'cancel' : 'search'}
                />
              </div>
            </div>
            }
            {tabs['remoteTestLink'] &&
              <TestLink />
            }
          </main>
        </Fragment>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  path: state.userReducer.path,
  patientList: state.userReducer.patientList,
  orgData: state.userReducer.orgData,
  userData: state.firebaseReducer.userData,
  subData: state.userReducer.subscriptionData,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload},
)(MProc)
