// Dependencies
import React, {Fragment, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'
import firebase from 'firebase/app'

// Actions
import {fetchOrgMemberData} from '../actions/fetchOrgMemberData'
import {pushOrgPermissions} from '../actions/pushOrgPermissions.js'
import {storePayload} from '../actions/storePayload.js'

// Components
import {
  Header, AsideDiv, Input, Button, SearchBar, OrgMemberThumb, Switch,
} from '@anishp16/lazarus-cds'
import ChangePassword from './ChangePassword.js'
import ContactUs from './ContactUs.js'
import Billing from './Billing.js'
import FullLoadingScreen from './FullLoadingScreen.js'

// Styles
import '../styles/Settings.css'
import '@anishp16/lazarus-cds/dist/index.css'

// Images
import User from '../images/white/User.svg'

function Settings(props) {
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const copyText = props.userData ? 'localhost:3000/invite/' + props.userData.Membership.Organization : ''
  const defaultTabs = {
    admin: false,
    insights: false,
    plan: false,
    password: false,
    contactUs: false,
  }
  const defaultOrgMemberPermissions = {
    'Create patient accounts': true,
    'Receive patient accounts': true,
    'Send patient accounts': true,
    'View all patient accounts': true,
    'Create tests': true,
    'Receive tests': true,
    'Send test requests': true,
  }
  const [tabs, setTabs] = useState({defaultTabs})
  const [isLinkCoppied, setIsLinkCoppied] = useState(false)
  const [orgMemberIds, setOrgMemberIds] = useState(null)
  /* Full list unfiltered by search */
  const [orgMemberList, setOrgMemberList] = useState([])
  /* List filtered by search */
  const [renderedOrgMemberList, setRenderedOrgMemberList] = useState(orgMemberList)
  /* Expanded admin permissions */
  const [renderedPermissions, setRenderedPermissions] = useState({})
  /* Org data with updated permissions */
  const [newOrgDataPermissions, setNewOrgDataPermissions] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [invitees, setInvitees] = useState(null)
  const [isOwner, setIsOwner] = useState(false)
  const [newOrgMemberPermissions, setNewOrgMemberPermissions] = useState(defaultOrgMemberPermissions)
  const [newOrgMember, setNewOrgMember] = useState({})

  const handleOrgMemberData = () => {
    if (props.orgData) {
      const newOrgMemberIds = Object.keys(props.orgData.Permissions)
      setOrgMemberIds(newOrgMemberIds)
      props.fetchOrgMemberData(props.firestore, newOrgMemberIds)
    }
  }

  const handleOrgPermissions = () => {
    if (props.orgData) {
      setNewOrgDataPermissions(props.orgData.Permissions)
    }
  }

  /* Set default tab to admin */
  useEffect(() => {
    // const isOwner = props.orgData && props.orgData.Owner === firebase.auth().currentUser.uid
    const isOwner = props.userData && props.userData.Membership.Auth === 'Super Admin'
    if (isOwner) {
      const isActive = props.orgData.Sub && props.orgData.Sub.isActive
      setIsOwner(true)
      if (isActive && !props.isSubscriptionFull) {
        onClick('admin')
      } else {
        onClick('plan')
      }
    } else {
      onClick('plan')
    }
    handleOrgMemberData()
    handleOrgPermissions()
  }, [props.orgData])

  useEffect(() => {
    if (props.orgPhysiciansData && orgMemberIds) {
      const newOrgMemberList = orgMemberIds.map((id) => {
        return ({
          ...props.orgPhysiciansData[id],
          Id: id,
        })
      })
      setOrgMemberList(newOrgMemberList)
      if (searchValue) {
        const searchedOrgMembers = Helpers.filterByName(newOrgMemberList, searchValue)
        setRenderedOrgMemberList(searchedOrgMembers)
      } else {
        setRenderedOrgMemberList(newOrgMemberList)
      }
    }
  }, [props.orgPhysiciansData])

  const onClick = (tab) => {
    setTabs({
      ...defaultTabs,
      [tab]: true,
    })
  }

  const sendInvite = () => {
    const isFormComplete = (
      newOrgMember.first_name &&
      newOrgMember.last_name &&
      newOrgMember.email &&
      newOrgMember.password
    )
    if (isFormComplete) {
      props.storePayload({
        isLoading: true,
      })
      const dataToSend = {
        ...newOrgMember,
        org_id: props.userData.Membership.Organization,
        user_id: userId,
        Permissions: newOrgMemberPermissions,
      }
      const jsonData = JSON.stringify(dataToSend)
      fetch(process.env.REACT_APP_URL + 'physicianInvite', {
        method: 'POST',
        // mode: 'cors',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        body: jsonData,
      })
          .then((res) => {
            props.storePayload({
              isLoading: false,
            })
            if (res.status === 409) {
              res.json().then((data) => {
                props.storePayload({
                  userMessage: data.message, // 'The email is already in use.'
                  notificationIcon: 'warning',
                  notificationType: 3,
                })
              })
            } else {
              props.storePayload({
                userMessage: 'Invite successfully sent',
                notificationIcon: 'check',
                notificationType: 2,
              })
              setNewOrgMember({})
              setNewOrgMemberPermissions(defaultOrgMemberPermissions)
            }
          })
          .catch((res) => {
            props.storePayload({
              isLoading: false,
            })
            props.storePayload({
              userMessage: 'Error: Unable to send invite',
              notificationIcon: 'warning',
              notificationType: 3,
            })
          })
    } else { // Form not complete
      props.storePayload({
        userMessage: 'Please complete the form before sending invitation',
        notificationIcon: 'warning',
        notificationType: 3,
      })
    }
  }

  const onChangeSearchBar = (event) => {
    event.preventDefault()
    setSearchValue(event.target.value)
    const searchedOrgMembers = Helpers.filterByName(orgMemberList, event.target.value)
    setRenderedOrgMemberList(searchedOrgMembers)
  }

  const onClickPermission = (id, permission, value) => {
    const updatedOrgPermissions = {
      ...newOrgDataPermissions,
      [id]: {
        ...newOrgDataPermissions[id],
        [permission]: value,
      },
    }
    setNewOrgDataPermissions(updatedOrgPermissions)
    props.pushOrgPermissions(
        props.firestore,
        props.userData.Membership.Organization,
        updatedOrgPermissions,
    )
  }

  const onClickPermission2 = (permission) => {
    setNewOrgMemberPermissions({
      ...newOrgMemberPermissions,
      [permission]: !newOrgMemberPermissions[permission],
    })
  }


  const permissionRender = (name, id, permission) => {
    return (
      <Fragment>
        <div
          className={
            newOrgDataPermissions[id][permission] ?
            'filter-div tab selected-tab' :
            'filter-div tab'
          }
        >
          <div className='title'>
            <p>{name}</p>
          </div>
          <div className='flex'>
            <Switch
              checked={newOrgDataPermissions[id][permission]}
              onChange={() => {
                onClickPermission(id, permission, !newOrgDataPermissions[id][permission])
              }}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  const permissionRender2 = (permission) => {
    return (
      <Fragment>
        <div
          className={
            newOrgMemberPermissions[permission] ?
            'filter-div tab selected-tab' :
            'filter-div tab'
          }
        >
          <div className='title'>
            <p>{permission}</p>
          </div>
          <div className='flex'>
            <Switch
              checked={newOrgMemberPermissions[permission]}
              onChange={() => {
                onClickPermission2(permission)
              }}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  const onChangeForm = (event) => {
    setNewOrgMember({
      ...newOrgMember,
      [event.target.name]: event.target.value,
    })
  }

  const onClickInviteByEmail = () => {
    const orgId = props.userData.Membership.Organization
    let emails = invitees.indexOf(',') !== -1 ? invitees.split(',') : [invitees]
    emails = emails.map((email) => email.replace(/\s+/g, ''))
    const dataToSend = {
      OrgId: orgId,
      url: `beta.lazarusderm.com/invite/${orgId}`,
      uid: userId,
      emails: emails,
    }
    const raw = JSON.stringify(dataToSend)
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(process.env.REACT_APP_URL + 'physicianInvite', requestOptions)
        .then((response) => response.text())
        // .then((result) => console.log('****PHYSICIAN INVITE RES******', result))
        .catch((error) => console.log('error', error))
  }

  return (
    <div className='settings-page'>
      {props.userData &&
      <Fragment>
        {props.isLoading && <FullLoadingScreen />}
        <Header
          image={props.userData.Info.ImageRef || User}
          name={props.userData.Info.Name}
          practice={props.userData.Info.Practice}
        />
        <main>
          <aside>
            {isOwner &&
              <AsideDiv
                text='Admin'
                isSelected={tabs['admin']}
                type={1} // For large number
                onClick={() => onClick('admin')}
                style={{cursor: 'pointer'}}
              />
            }
            {/* <AsideDiv
              text='Insights'
              isSelected={tabs['insights']}
              type={1} // For large number
              onClick={() => onClick('insights')}
              style={{cursor: 'pointer'}}
            /> */}
            <AsideDiv
              text='Plan'
              isSelected={tabs['plan']}
              type={1} // For large number
              onClick={() => onClick('plan')}
              style={{cursor: 'pointer'}}
            />
            <AsideDiv
              text='Password'
              isSelected={tabs['password']}
              type={1} // For large number
              onClick={() => onClick('password')}
              style={{cursor: 'pointer'}}
            />
            <AsideDiv
              text='Contact Us'
              isSelected={tabs['contactUs']}
              type={1} // For large number
              onClick={() => onClick('contactUs')}
              style={{cursor: 'pointer'}}
            />
            {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
              <p>{`AI Results: `}</p>
              <div className='flex'>
                <Switch
                  checked={props.isAIActive}
                  onChange={() => props.storePayload({isAIActive: !props.isAIActive})} />
              </div>
            </div> */}
          </aside>
          <div className='settings-right'>
            {tabs['admin'] && (
              <Fragment>
                <div className='admin'>
                  <div className='admin-tab-email'>
                    <p className='margin-top-0 settings-header'>
                      Add user to organization
                    </p>
                    <div className='admin-card'>
                      <form>
                        <Input
                          label='Physician first name'
                          name='first_name'
                          onChange={onChangeForm}
                        />
                        <Input
                          label='Physician last name'
                          name='last_name'
                          onChange={onChangeForm}
                        />
                        <Input
                          label='Contact Email'
                          name='email'
                          type='email'
                          onChange={onChangeForm}
                        />
                        <Input
                          label='Password'
                          name='password'
                          onChange={onChangeForm}
                          type='password'
                        />
                      </form>
                      <div className='new-member-permissions'>
                        <div>
                          <p>Patient Permissions</p>
                          {permissionRender2('Create patient accounts')}
                          {permissionRender2('Receive patient accounts')}
                          {permissionRender2('Send patient accounts')}
                          {permissionRender2('View all patient accounts')}
                        </div>
                        <div>
                          <p>Test Permissions</p>
                          {permissionRender2('Create tests')}
                          {permissionRender2('Receive tests')}
                          {permissionRender2('Send test requests')}
                        </div>
                      </div>
                      <Button
                        style={{margin: '1em 0.5em 0.5em'}}
                        width={'calc(100% - 1em)'}
                        type={1}
                        text='Send Invite'
                        onClick={sendInvite}
                      />
                    </div>
                    {/* <div className='top'>
                      <p className='margin-top-0'>INVITE BY EMAIL</p>
                      <textarea onChange={(event) => setInvitees(event.target.value)}/>
                      <Button
                        type={2}
                        text='Invite via email'
                        onClick={onClickInviteByEmail}
                      />
                    </div>
                    <div className='bottom'>
                      <p>INVITE VIA LINK</p>
                      <div className='link-div'>
                        <span>{copyText}</span>
                        <Button
                          type={1}
                          text='Copy Link'
                          width='25%'
                          onClick={() => Helpers.copyToClipBoard(copyText, setIsLinkCoppied)}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className='organization'>
                    <p className='margin-top-0 settings-header'>Organization members</p>
                    <SearchBar
                      onChange={onChangeSearchBar}
                      isShowing={true}
                      style={{height: '2em'}}
                    />
                    <div className='org-member-list'>
                      {renderedOrgMemberList.map((orgMember, i) => {
                        return (
                          <Fragment key={i}>
                            <OrgMemberThumb
                              key={i}
                              orgMember={orgMember}
                              onClick={() => setRenderedPermissions({
                                ...renderedPermissions,
                                [orgMember.Id]: !renderedPermissions[orgMember.Id],
                              })}
                            />
                            {renderedPermissions[orgMember.Id] &&
                              <div className='permission-list'>
                                <div>
                                  <p>Patient Permissions</p>
                                  {permissionRender(
                                      'Create patient accounts',
                                      orgMember.Id,
                                      'Create patient accounts',
                                  )}
                                  {permissionRender(
                                      'Receive patient accounts',
                                      orgMember.Id,
                                      'Receive patient accounts',
                                  )}
                                  {permissionRender(
                                      'Send patient accounts',
                                      orgMember.Id,
                                      'Send patient accounts',
                                  )}
                                  {permissionRender(
                                      'View all patient accounts',
                                      orgMember.Id,
                                      'View all patient accounts',
                                  )}
                                </div>
                                <div>
                                  <p>Test Permissions</p>
                                  {permissionRender(
                                      'Create tests',
                                      orgMember.Id,
                                      'Create tests',
                                  )}
                                  {permissionRender(
                                      'Receive tests',
                                      orgMember.Id,
                                      'Receive tests',
                                  )}
                                  {permissionRender(
                                      'Send test requests',
                                      orgMember.Id,
                                      'Send test requests',
                                  )}
                                </div>
                              </div>
                            }
                          </Fragment>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {tabs['password'] && <ChangePassword />}
            {tabs['contactUs'] && <ContactUs />}
            {tabs['plan']
              && <Billing 
                orgData = {props.orgData}
                subData = {props.subData}
                userData = {props.userData}
              />
            }
          </div>
        </main>
      </Fragment>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  orgPhysiciansData: state.userReducer.orgPhysiciansData,
  isSubscriptionFull: state.userReducer.isSubscriptionFull,
  subData: state.userReducer.subscriptionData,
  isAIActive: state.userReducer.isAIActive,
  isLoading: state.userReducer.isLoading,
})

export default connect(
    mapStateToProps,
    {fetchOrgMemberData, pushOrgPermissions, storePayload},
)(Settings)
