// Dependencies
import React, {useState} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'

// Components
import {Button, Input} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/ContactUs.css'

function ContactUs(props) {
  const textAreaRef = React.createRef()
  const [message, setMessage] = useState(null)
  const [contactUsMessage, setContactUsMessage] = useState(null)
  const pushKey = props.database.ref('Dermatology/Feedback').push().key
  const onChangeTextArea = (event) => {
    setMessage(event.target.value)
  }

  const onClickSendMessage = (event) => {
    event.preventDefault()
    if (!contactUsMessage) { // Limit to one message per render
      if (message.length > 0) {
        props.firestore.collection('LCDS')
            .doc('Something')
            .collection('Support')
            .add({
              Message: message,
              From: firebase.auth().currentUser.uid,
              Email: firebase.auth().currentUser.email,
              Date: new Date().getTime(),
              Phone: props.userData.Info.Phone || null,
            })
            .then(() => {
              setMessage('')
              setContactUsMessage(
                "Thank you for your feedback. We will contact you soon.",
              )
            })
            .catch((error) => {
              // console.log('Error', error)
              setContactUsMessage(
                  "There was an unexpected error. Try again later.",
              )
            })
      } else {
        setContactUsMessage(
            "Looks like you haven't written anything. Please try again."
        )
      }
    }
  }

  const firstName = props.userData.Info.FirstName ? props.userData.Info.FirstName : props.userData.Info.Name.split(' ')[0]
  const lastName = props.userData.Info.LastName ? props.userData.Info.LastName : props.userData.Info.Name.split(' ')[1]
  return (
    <div className='contact-us-content'>
      <form>
        <Input
          label='First Name'
          value={firstName || null}
          disabled={true}
        />
        <Input
          label='Last Name'
          value={lastName || null}
          disabled={true}
        />
        <Input
          label='Phone*'
          value={props.userData.Info.Phone || null}
          disabled={true}
        />
        <Input
          label='Email*'
          value={props.userData.Info.Email || null}
          disabled={true}
        />
      </form>
      <form id='contact-us-message-form'>
        <p>Message</p>
        <textarea ref={textAreaRef} onChange={onChangeTextArea} value={message} />
        <div className='user-message-container'>
          {contactUsMessage &&
          <p className='user-message'>{contactUsMessage}</p>
          }
        </div>
        <Button
          text='Send Message'
          type={2}
          onClick={onClickSendMessage}
        />
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  database: state.firebaseReducer.database,
  userData: state.firebaseReducer.userData,
})

export default connect(
    mapStateToProps,
)(ContactUs)
