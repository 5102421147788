// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'
import firebase from 'firebase/app'
import 'firebase/auth'

// Actions
import {storePayload} from '../actions/storePayload.js'
import {fetchOrgMemberData} from '../actions/fetchOrgMemberData.js'

// Components
import {
  PatientHeader,
  Button,
  SearchBar,
  OrgMemberThumb,
  Input,
} from '@anishp16/lazarus-cds'
// import {
//   PatientHeader,
//   Button,
//   SearchBar,
//   OrgMemberThumb,
//   Input,
// } from 'lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'
import '../styles/Patient.css'

/*
  PatientHeaderContainer shows patient details when clicked and
  allows patient-sharing when share button is clicked.
  It pulls organization member data to allow user to choose from all org members
  with permission to receive patients.
*/

function PatientHeaderContainer(props) {
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null

  const [isSharePatientShowing, setIsSharePatientShowing] = useState(false)
  const [orgMembersList, setOrgMembersList] = useState([])
  const [renderedOrgMemberList, setRenderedOrgMemberList] = useState([])
  const [orgMemberIds, setOrgMemberIds] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [orgMemberList, setOrgMemberList] = useState([])
  const [selectedOrgMember, setSelectedOrgMember] = useState(null)
  /* Renders modal for user to choose physician to share patient with */
  const [isSharePatient, setIsSharePatient] = useState(false)
  /* Renders patient info when true */
  const [isPatientClicked, setIsPatientClicked] = useState(false)

  useEffect(() => {
    if (props.currentPatient && props.orgPhysiciansData && orgMemberIds) {
      const newOrgMemberList = []
      orgMemberIds.forEach((id) => {
        const orgMember = props.orgPhysiciansData[id]
        const isOrgMemberSharedWith = props.orgPatientsObj[props.currentPatient.ID].SharedWith[id]
        const hasPermission =
          props.orgData.Permissions[id]['Receive patient accounts']
        if (!isOrgMemberSharedWith && hasPermission) {
          newOrgMemberList.push({
            ...orgMember,
            Id: id,
          })
        }
      })
      setOrgMemberList(newOrgMemberList)
      setRenderedOrgMemberList(newOrgMemberList)
    }
  }, [props.orgPhysiciansData])

  const handleOrgMemberData = () => {
    if (props.orgData) {
      const newOrgMemberIds = Object.keys(props.orgData.Permissions)
      setOrgMemberIds(newOrgMemberIds)
      props.fetchOrgMemberData(props.firestore, newOrgMemberIds)
    }
  }

  useEffect(() => {
    handleOrgMemberData()
  }, [props.orgData])

  const onChangeSearchBar = (event) => {
    event.preventDefault()
    setSearchValue(event.target.value)
    const searchedOrgMembers =
      Helpers.filterByName(orgMemberList, event.target.value)
    setRenderedOrgMemberList(searchedOrgMembers)
  }

  useEffect(() => {
    setRenderedOrgMemberList(orgMemberList)
  }, [orgMemberList])

  const onClickSharePatient = () => {
    const orgId = props.userData.Membership.Organization
    if (selectedOrgMember) {
      props.firestore
          .collection(`LCDS`)
          .doc('Something')
          .collection('Organizations')
          .doc(orgId)
          .collection('Patients')
          .doc(props.currentPatient.ID)
          .update({
            SharedWith: {
              ...props.orgPatientsObj[props.currentPatient.ID].SharedWith,
              [selectedOrgMember.Id]: true,
            },
          })
          .then(() => {
            const newOrgMemberList =
              orgMemberList.filter((member) => member !== selectedOrgMember)
            setOrgMemberList(newOrgMemberList)
            setSelectedOrgMember(null)
            props.storePayload({
              userMessage: 'Patient has successfully been shared with ' +
              selectedOrgMember.Info.Name,
              notificationType: 2,
              notificationIcon: 'check',
            })
            setIsSharePatientShowing(false)
            setSearchValue(null)
          })
          .catch(() => {
            props.storePayload({
              userMessage: 'There was an error sharing this patient with the selected organization member.',
              notificationType: 3,
              notificationIcon: 'warning',
            })
          })
    }
  }

  const {patient} = props

  return (
    <Fragment>
      {patient &&
        <div className='patient-header-container'>
          <PatientHeader
            name={patient.Name || '   '}
            image={patient.ImageRef || null}
            birthdate={Helpers.formatDate(patient.Birthday)}
            lastUpdated={Helpers.timeStampToDate(patient.LastUpdate)}
            sex={patient.Sex}
            onClick={() => setIsPatientClicked(!isPatientClicked)}
            onClickSharePatient={() => {
              const hasPermission = props.orgData.Permissions[userId]['Send patient accounts']
              if (hasPermission) {
                if (renderedOrgMemberList && renderedOrgMemberList.length) {
                  setIsSharePatient(true)
                  setSearchValue('')
                  setIsSharePatientShowing(!isSharePatientShowing)
                } else {
                  setIsSharePatientShowing(false)
                  props.storePayload({
                    userMessage: 'There are currently no members of your organization who have permission to receive patients, and do not already have access to this patient.',
                    notificationType: 3,
                    notificationIcon: 'warning',
                  })
                }
              } else { // no permission
                props.storePayload({
                  userMessage: 'You currently do not have permission to share patient accounts.',
                  notificationType: 3,
                  notificationIcon: 'warning',
                })
              }
            }}
          />
        </div>
      }
      {isSharePatient &&
        <Fragment>
          {isSharePatientShowing &&
            <div
              className='gray-screen'
              onClick={() => setIsSharePatientShowing(false)}
            />
          }
          <div
            className={
              isSharePatientShowing ?
              'sharePatientModal slideInUp' :
              'sharePatientModal slideOutDown'
            }
          >
            <p className='margin-top-0 settings-header'>Share patient with...</p>
            { searchValue !== null && <SearchBar
              isShowing={true}
              onChange={onChangeSearchBar}
              searchValue={searchValue}
            />}
            <div className='org-member-list'>
              {renderedOrgMemberList.length > 0 &&
              renderedOrgMemberList.map((orgMember, i) => {
                if (orgMember) {
                  return (
                    <Fragment key={i}>
                      <OrgMemberThumb
                        key={i}
                        isSelected={orgMember === selectedOrgMember}
                        orgMember={orgMember}
                        onClick={() => {
                          setSelectedOrgMember(orgMember)
                        }}
                      />
                    </Fragment>
                  )
                }
              })}
            </div>
            <Button
              text='Share'
              type={1}
              onClick={onClickSharePatient}
            />
            <Button
              text='Cancel'
              type={2}
              style={{marginTop: '0.5em'}}
              onClick={() => setIsSharePatientShowing(false)}
            />
          </div>
        </Fragment>
      }
      {isPatientClicked &&
        <div
          className='patient-info-container'
          onClick={() => setIsPatientClicked(false)}
        >
          <div className='patient-info'>
            <Input
              disabled={true}
              label='Phone'
              value={patient.Phone}
              style={{width: '50%'}}
            />
            <Input
              disabled={true}
              label='Email'
              value={patient.Email}
              style={{width: '50%'}}
            />
          </div>
        </div>
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  patientsObj: state.userReducer.patientsObj,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  currentPatient: state.userReducer.currentPatient,
  patientsData: state.userReducer.patientsData,
  isLoading: state.userReducer.isLoading,
  subData: state.userReducer.subscriptionData,
  orgPhysiciansData: state.userReducer.orgPhysiciansData,
})

export default connect(
    mapStateToProps,
    {storePayload, fetchOrgMemberData},
)(PatientHeaderContainer)

