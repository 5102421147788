// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'
import firebase from 'firebase/app'

// Actions
import {storePayload} from '../actions/storePayload'

// Components
import {AsideDiv, Input, PatientHeader, Button, Modal} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'
import '../styles/ContactPatient.css'

// Images
import notes from '../images/notes.svg'
import check from '../images/check.svg'
import warning from '../images/warning.svg'

function ContactPatient(props) {
  const [isHighConcern, setIsHighConcern] = useState(false)
  const [isFollowUpModal, setIsFollowUpModal] = useState(false)
  const [isFollowUpModalShowing, setIsFollowUpModalShowing] = useState(false)
  const [message, setMessage] = useState(null)
  const textAreaRef = React.createRef()
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const link = `https://v3.d1dy0a21ndt7ut.amplifyapp.com/?state=${userId}&patient=${props.patient.ID}&orgId=${props.userData.Membership.Organization}`
  const defaultTestRequestMessage =
    `Hi ${props.patient.Name},\r\n\r\nPlease complete your scheduled skin cancer self-assessment for ${props.userData.Info.Name}. To begin, open the link below, preferably on a mobile device.\r\n\r\nFor a skin cancer screening, please collect three images of your pigmented skin lesion and upload them through our portal. ${props.userData.Info.Name} will review them promptly and reach out to explain what you will need to do next.\r\n\r\nSincerely,\r\n${props.userData.Info.Name}`
  const defaultHighConcernMessage =
  `Dear ${props.patient.Name}, \r\n\r\nYour skin screening is complete: Immediate Followup Necessary. \r\n\r\nThe skin screening you ordered ${props.currentTest && props.currentTest.DateTaken ? `on ${Helpers.timeStampToDate(props.currentTest.DateTaken)} ` : ''}has been completed, and ${props.userData.Info.Name} recommends immediate further testing for a complete diagnosis. Please review the included notes, and take the necessary steps to consult with a physician in your area.`
  const highConcernMessageBody = `Your physician, ${props.userData.Info.Name}, has reviewed the test and recommends immediate further testing for a complete diagnosis`
  const lowConcernMessageBody = `Your physician, ${props.userData.Info.Name}, has reviewed the test and does not recommend immediate further testing for a complete diagnosis`
  const defaultLowConcernMessage =
  `Dear ${props.patient.Name}, Your skin screen results are available. The skin screening you ordered ${props.currentTest && props.currentTest.DateTaken ? `on ${Helpers.timeStampToDate(props.currentTest.DateTaken)} ` : ''}has been completed, and ${props.userData && props.userData.Info.Name} does not recommend further testing at this time.\r\n\r\nYour attention to your health is a good practice. To sustain it, consider scheduling a physician checkup. It is recommended to take one general dermatology checkup each year. If another specific concern arises, please schedule an appointment sooner.`
  useEffect(() => {
    if (textAreaRef.current) {
      if (props.isTestRequest) {
        setMessage(defaultTestRequestMessage)
        textAreaRef.current.value = defaultTestRequestMessage
      }
      // } else {
      //   if (isHighConcern) {
      //     setMessage(defaultHighConcernMessage)
      //     textAreaRef.current.value = defaultHighConcernMessage
      //   } else {
      //     setMessage(defaultLowConcernMessage)
      //     textAreaRef.current.value = defaultLowConcernMessage
      //   }
      // }
    }
  }, [isHighConcern])

  /* If contacting patient regarding results, if test is possibly melanoma,
    set isHighConcern
  */
  useEffect(() => {
    if (props.test) {
      const individualTest =
        (props.testSetIndex || props.testSetIndex === 0) ?
        props.test.TestSets[0].Tests[props.testSetIndex].data :
        props.test
      const isMelanoma = individualTest.Results === 'Melanoma'
      const isPossibleMelanoma = Helpers.isPossibleMelanoma(props.test, props.testSetIndex)
      const newIsHighConcern = isMelanoma || isPossibleMelanoma
      setIsHighConcern(newIsHighConcern)
    }
  }, [props.test])

  const onClickSend = () => {
    if (props.isTestRequest) {
      if (Helpers.isStringValid(message)) { // has valid message
        props.onClickSendTestRequest(message)
      } else { // no valid message
        props.storePayload({
          userMessage: 'Please include a message for the patient',
          notificationType: 3,
          notificationIcon: 'warning',
        })
      }
    } else { // not test request
      if (isFollowUpModalShowing) {
        props.onClickSendFollowUp(message, isHighConcern)
      } else { // follow up modal not showing
        setIsFollowUpModal(true)
        setIsFollowUpModalShowing(true)
      }
    }
  }

  return (
    <div className='contact-patient'>
      <div className='contact-patient-content'>
        <PatientHeader
          name={props.patient.Name}
          birthdate={Helpers.formatDate(props.patient.Birthday)}
          lastUpdated={Helpers.timeStampToDate(props.patient.LastUpdate)}
          sex={props.patient.Sex}
        />
        {isFollowUpModal &&
          <Modal
            confirmationFunc={() => {
              props.onClickSendFollowUp(message, isHighConcern)
              setIsFollowUpModalShowing(false)
            }}
            cancelationFunc={() => setIsFollowUpModalShowing(false)}
            confirmationText={'Send'}
            cancelationText={'Cancel'}
            message={'Are you sure you would like to send this email? Please note that the default message is based on the AI results'}
            image={warning}
            time={Helpers.timeStampToTime(Date.now())}
            isVisible={isFollowUpModalShowing}
          />
        }
        <div className='bottom'>
          <div className='left'>
            <Input
              disabled={true}
              label='Phone'
              type='number'
              value={props.currentPatient.Phone || 'N/A'}
            />
            <Input
              disabled={true}
              label='Email'
              value={props.currentPatient.Email || 'N/A'}
            />
            {!props.isTestRequest &&
              <AsideDiv
                type={2}
                image={check}
                text='High Concern'
                number={isHighConcern ? 'Yes' : 'No'}
                switch={true}
                isSelected={isHighConcern}
                onChange={() => setIsHighConcern(!isHighConcern)}
              />
            }
          </div>
          <div className='right'>
            {!props.isTestRequest ? (
              <Fragment>
                <div className='flex justify-space-between align-start'>
                  <p className='margin-0'>Message</p>
                  <img src={notes} alt='notes' />
                </div>
                <div className='default-message'>
                  <p>
                    {isHighConcern ? highConcernMessageBody : lowConcernMessageBody}
                  </p>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div />
                <div />
              </Fragment>
            )}
            <div>
              <p>Notes</p>
              <textarea
                onChange={(event) => setMessage(event.target.value)}
                ref={textAreaRef}
              />
            </div>
          </div>
        </div>
        <footer>
          <Button
            type={2}
            text='Cancel'
            onClick={props.cancel}
          />
          <Button
            type={1}
            text='Send'
            onClick={
              onClickSend
            }
          />
        </footer>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  userData: state.firebaseReducer.userData,
})

export default connect(
    mapStateToProps, {storePayload},
)(ContactPatient)
