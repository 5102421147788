// Dependencies
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

// Actions
import {storeValue} from '../actions/storeValue.js'

// Components
import {Input, DateInput, SexInput, Button} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/AddPatient.css'

/*
  NewPatientForm is used for AddPatient accessed through Dashboard
  and "New Patient" in M-Proc
  It stores form in userReducer as newPatient
  props.isTestRequest is true if request is for existing patient
*/
function NewPatientForm(props) {
  const [form, setForm] = useState({})
  const initialDateObj = {
    month: '',
    day: '',
    year: '',
  }

  const [dateObj, setDateObj] = useState({initialDateObj})
  const [isGenderInformationModal, setIsGenderInformationModal] = useState(false)

  useEffect(() => props.storeValue('newPatient', form), [form])

  useEffect(() => {
    const yyyymmdd = `${dateObj.year}-${dateObj.month}-${dateObj.day}`
    setForm({
      ...form,
      birthDate: yyyymmdd,
    })
  }, [dateObj])

  const onChangeForm = (event, name, value) => {
    if (event) {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      })
    } else {
      setForm({
        ...form,
        [name]: value,
      })
    }
  }

  const onChangeDate = (event) => {
    setDateObj({
      ...dateObj,
      [event.target.name]: event.target.value,
    })
  }

  const onChangeSex = (option) => {
    setForm({
      ...form,
      sex: option,
    })
  }

  return (
    <form>
      <Input
        label='First Name'
        name='firstName'
        onChange={onChangeForm}
        disabled={props.isTestRequest || props.isSummary}
        value={props.firstName || undefined}
      />
      <Input
        label='Last Name'
        name='lastName'
        onChange={onChangeForm}
        disabled={props.isTestRequest || props.isSummary}
        value={props.lastName || undefined}
      />
      <div className='flex justify-space-between'>
        <DateInput
          label='Date of Birth (mm/dd/yyyy)'
          name='birthDate'
          onChange={onChangeDate}
          style={{width: '48%'}}
          disabled={props.isTestRequest || props.isSummary}
          value={props.birthDate || undefined}
        />
        <div className='sex-input-container'>
          <SexInput
            // style={{width: '48%'}}
            sex={form.sex}
            onChange={onChangeSex}
            disabled={props.isTestRequest || props.isSummary}
            value={props.sex || undefined}
          />
          {(!props.isTestRequest || props.gender) && <p
            className='gender-information'
            onClick={() => {
              setIsGenderInformationModal(!isGenderInformationModal)
            }}
          >
            + Gender Information
          </p>
          }
          {isGenderInformationModal &&
            <div className='gender-information-modal'>
              <Input
                label=''
                name='gender'
                disabled={props.isTestRequest || props.isSummary}
                onChange={onChangeForm}
                value={props.gender || undefined}
              />
            </div>
          }
        </div>
      </div>
      <Input
        label='Phone'
        name='phone'
        width='50%'
        type='number'
        onChange={onChangeForm}
        disabled={props.isTestRequest || props.isSummary}
        value={props.phone || undefined}
      />
      <Input
        label='Email'
        name='email'
        onChange={onChangeForm}
        disabled={props.isTestRequest || props.isSummary}
        value={props.email || undefined}
      />
      {/* <Button
        text='Create Patient Account'
        type={1}
        onClick={createPatientAccount}
      /> */}
      <Link to={props.isMprocRequest || props.isTestRequest ? '/mproc' : '/'}>
        <Button
          text='Cancel'
          type={2}
        />
      </Link>
    </form>
  )
}

const mapStateToProps = (state) => ({
  newPatient: state.userReducer.newPatient,
  isMprocRequest: state.userReducer.isMprocRequest,
})

export default connect(
    mapStateToProps,
    {storeValue},
)(NewPatientForm)
