// Dependencies
import React, {useEffect, useState, Fragment} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import Helpers from '../Helpers.js'
import QRCode from 'qrcode.react'
import {PDFDownloadLink} from '@react-pdf/renderer'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
import {fetchPatientData} from '../actions/fetchPatientData.js'

// Components
import {
  AsideDiv,
  PatientHeader,
  Switch,
  Input,
  FloatingButton,
  ConfidenceBar,
  Loader,
  Button,
  Modal,
} from '@anishp16/lazarus-cds'
import TestPdf from './TestPdf'
import LocalTestPdf from './LocalTestPdf'
import ImageUpload from './ImageUpload'
import ContactPatient from './ContactPatient'
import FullLoadingScreen from './FullLoadingScreen'
import FloatingActionButton from './FloatingActionButton'
import PatientHeaderContainer from './PatientHeaderContainer'
import Gallery from './Gallery'

// Styles
import '../styles/MoleTrackingTest.css'

// Images
import accuracy from '../images/accuracy.svg'
import leftArrowCircle from '../images/LeftArrowCircle.svg'
import rightArrowCircle from '../images/RightArrowCircle.svg'
import check from '../images/check.svg'
import grid from '../images/grid.svg'
import warning from '../images/warning.svg'
import blockGreen from '../images/blockGreen.svg'
import flagImg from '../images/flag.svg'
import notesImg from '../images/notes.svg'
import manFront from '../images/wirePerson/manFront.jpg'
import manBack from '../images/wirePerson/manBack.jpg'
import womanFront from '../images/wirePerson/womanFront.jpg'
import womanBack from '../images/wirePerson/womanBack.jpg'

function MoleTrackingTest(props) {
  const [flag, setFlag] = useState(false)
  const [heatMap, setHeatMap] = useState(false)
  const [notes, setNotes] = useState('')
  const [isReviewModal, setIsReviewModal] = useState(false)
  const [isReviewModalShowing, setIsReviewModalShowing] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [timeOfType, setTimeOfType] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [patient, setPatient] = useState(null)
  const [patientId, setPatientId] = useState(null)
  const [testId, setTestId] = useState(null)
  const [mProcId, setMProcId] = useState(null)
  const [testSetIndex, setTestSetIndex] = useState(null)
  const [patientTests, setPatientTests] = useState(null)
  const [qrCode, setQrCode] = useState(false)
  const [test, setTest] = useState(null)
  const [testData, setTestData] = useState(null)
  const [contactPatient, setContactPatient] = useState(false)
  const [isPdfReady, setIsPdfReady] = useState(false)
  const [isPatientClicked, setIsPatientClicked] = useState(false)
  const [isTestRequest, setIsTestRequest] = useState(false)
  const [isTestUpload, setIsTestUpload] = useState()
  const [areFloatingButtons, setAreFloatingButtons] = useState(false)
  const [areFloatingButtonsShowing, setAreFloatingButtonsShowing] = useState(false)
  const [areNotesUpdated, setAreNotesUpdated] = useState(false)
  const [isGalleryView, setIsGalleryView] = useState(false)
  const notesRef = React.createRef()
  const asideDivStyle = {backgroundColor: 'white', cursor: 'pointer'}

  useEffect(() => {
    props.storePayload({isFabOpen: false})
  }, [])

  /* If accessed through patient page */
  useEffect(() => {
    if (props.currentTest) {
      const currentTest = props.currentTest
      if (currentTest.PingTime) { // Review request from patient
        if (currentTest.LastReview) {
          if (currentTest.LastReview.Time < currentTest.PingTime) {
            setIsReviewModal(true)
            setIsReviewModalShowing(true)
          }
        } else {
          setIsReviewModal(true)
          setIsReviewModalShowing(true)
        }
      }
      if (currentTest.data) {
        setFlag(currentTest.data.Flag)
        if (notesRef.current) {
          notesRef.current.value = test.data.Notes || ''
        }
      } else {
        setFlag(currentTest.Flag)
        if (notesRef.current) {
          notesRef.current.value = test.PhysicianNotes || ''
        }
      }
      setTest(currentTest)
      setIsLoading(false)
    }
  }, [props.currentTest])

  /* If url accessed directly */
  useEffect(() => {
    if (props.orgPatientsObj && (!props.currentPatient || !props.currentTest)) {
      const id = props.match.params.patientId
      if (props.orgPatientsObj[id]) {
        const patient = props.orgPatientsObj[id]
        patient['Patient_ID'] = id
        props.fetchPatientData(props.firestore, [id], {})
        setPatient(patient)
        props.storeValue('currentPatient', patient)
        props.storeValue('path', 'patient')
        /* If there are patients but not one with this id */
      } else if (Object.keys(props.patientsObj).length) {
        setRedirect(true)
      }
    }
  }, [props.orgPatientsObj])

  /* Set notes once text area is rendered */
  useEffect(() => {
    if (!areNotesUpdated && notesRef.current) {
      if (test.data) { // m-proc format
        notesRef.current.value = test.data.Notes || ''
        setAreNotesUpdated(true)
      } else {
        notesRef.current.value = test.PhysicianNotes || ''
        setAreNotesUpdated(true)
      }
    }

    /* accommodate tablet sizing */
    const mains = document.getElementsByTagName('MAIN')
    if (mains.length) {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const main = mains[0]
      if (main) {
        const em = parseFloat(getComputedStyle(main).fontSize)
        // main.style.height = `${windowHeight}px`
        main.style.width = `${windowWidth - (12.5 * em)}px`
        main.style.height = `${windowHeight - (9 * em)}px`
      }
    }
  }, [notesRef])

  /*  To enable direct access to link,
      store patient and test ids ( and optional mprocId) from url params
      If no patient matches patientId, redirect
      If no patientsData, fetch patient's data (tests)
  */
  useEffect(() => {
    if (props.orgPatientsObj) {
      const patientId = props.match.params.patientId
      setPatientId(patientId)
      const testId = props.match.params.testId
      setTestId(testId)
      const mProcId = props.match.params.mTrackId
      if (mProcId) {
        const testSetIndex = mProcId[mProcId.length - 1]
        setTestSetIndex(testSetIndex)
        setMProcId(mProcId)
      }
      let patient
      if (props.currentPatient) {
        patient = props.currentPatient
      } else if (props.orgPatientsObj[patientId]) {
        patient = props.orgPatientsObj[patientId]
      } else if (Object.keys(props.orgPatientsObj).length) {
        setRedirect(true)
      }
      setPatient(patient)
      if (Object.keys(props.patientsData).length) {
      } else if (props.firestore) {
        props.fetchPatientData(props.firestore, [patientId], {})
      }
    }
  }, [props.orgPatientsObj])

  /*  (If url directly accessed) Once tests are fetched (in fetchPatientData)
      store relevant data in component state using url params
  */
  useEffect(() => {
    if (!props.currentTest && patientId && testId && Object.keys(props.patientsData)) {
      const patientTests = props.patientsData[patientId].tests
      setPatientTests(patientTests)
      const testIdPrefix = testId.slice(0, testId.length - 2)
      /* test is either the local test or the m-proc group of tests */
      const test = patientTests.filter((patientTest) => patientTest.Id === testIdPrefix)[0]
      if (test) {
        setFlag(test.Flag)
        setNotes(test.PhysicianNotes)
        setTest(test)
        props.storeValue('currentTest', test)
        setIsLoading(false)
      } else { // Local test with id from url params doens't exist
        setIsLoading(false)
        setRedirect(true)
      }
    }
  }, [props.patientsData])

  useEffect(() => {
    if (isPdfReady) {
      setInterval(async () => {
        // button was being triggered several times
        if (document.getElementById('pdf-ready') && isPdfReady) {
          await document.getElementById('pdf-ready').click()
          await setIsPdfReady(false)
          // console.log('isPdf Ready', this.state.isPdfReady)
        }
      }, 4000)
    }
  }, [isPdfReady])

  const closeGallery = () => {
    setIsGalleryView(false)
  }

  const confirmReview = () => {
    props.firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .collection('DermatologyTests')
        .doc(props.currentTest.Id)
        .update({
          LastReview: {
            Time: Date.now(),
            Physician: firebase.auth().currentUser.uid,
          },
        })
        .then(() => {
          setIsReviewModalShowing(false)
          props.storePayload({
            userMessage: 'Your patient will be notified that you reviewed their upload',
            notificationIcon: 'check',
            notificationType: 2,
          })
        })
        .catch(() => {
          setIsReviewModalShowing(false)
          props.storePayload({
            userMessage: 'There was an error notifying your patient.',
            notificationIcon: 'warning',
            notificationType: 3,
          })
        })
  }

  // const formatFileNameDate = (str) => {
  //   if (str) {
  //     const fileNameDate = str.slice(0, 4) + '_' + str.slice(5, str.length)
  //     return fileNameDate.replace('-', '_')
  //   } else {
  //     return ''
  //   }
  // }

  const toggleIsTestUpload = () => {
    setIsTestUpload(!isTestUpload)
    props.storePayload({isFabShowing: false})
  }

  const closeFab = () => {
    props.storePayload({
      isFabOpen: false,
      isFabShowing: false,
    })
  }

  /* Update Flag/Notes values and update props.patientsData */
  const updateTest = (key, value) => {
    Helpers.updateLastUpdate(
        props.firestore,
        props.userData.Membership.Organization,
        patientId,
    )
    if (test.Type === 'Mole-Tracking') {
      const testSetId = testId.slice(0, testId.length - 2)
      props.firestore.
          collection('LCDS')
          .doc('Something')
          .collection('Patients')
          .doc(patientId)
          .collection('DermatologyTests')
          .doc(testSetId)
          .update({
            [key]: value,
          })
          .then(() => {
            /* Update test in front end */
            const newPatientsData = props.patientsData
            let testIndex
            /* Find index of test (indexOf(test) didn't work) */
            newPatientsData[patientId].tests.forEach((patientTest, i) => {
              if (patientTest.Id === test.Id) {
                testIndex = i
              }
            })
            newPatientsData[patientId].tests[testIndex][key] = value
            props.storeValue('patientsData', newPatientsData)
          })
          .catch((error) => {
            props.storePayload({
              userMessage: 'There was an error updating this test',
              notificationIcon: 'warning',
              notificationType: 3,
            })
          })
    }
  }

  /*
    Submit notes after user stops typing for set amount of time
  */
  useEffect((pauseLength = 2000) => {
    const isNotAllSpaces =
      notes.length === 0 ||
      notes.split('').some((char) => char !== ' ')
    const timer = setTimeout(() => {
      if (isNotAllSpaces) {
        const now = Date.now()
        if (isTyping && (now - timeOfType) >= pauseLength) {
          setIsTyping(false)
          updateTest('PhysicianNotes', notes)
        }
      } else {
        setIsTyping(false)
      }
    }, pauseLength)
    return () => clearTimeout(timer)
  }, [timeOfType])

  const onChangeNotes = async (event) => {
    await setNotes(event.target.value)
    setIsTyping(true)
    setTimeOfType(Date.now())
  }

  const testToRender = test
  // const testData = test && (
  //   test.Type === 'Local' ? test : test.data
  // )
  // console.log('test data', testData)

  useEffect(() => {
    const testData = test && (
      test.Type === 'M-Proc' ? test.data : test
    )
    setTestData(testData)
  }, [test])

  /* Sort hight to low */
  const sortedConditions = (reasoning) => {
    return (
      reasoning.sort((a, b) => b.Confidence - a.Confidence)
    )
  }

  // /* Conditions to be rendered under first result */
  // const lesserConditions =
  //   testToRender ? (
  //     testToRender.Type === 'Local' ? (
  //       sortedConditions(testToRender.Reasoning)
  //           .slice(1, sortedConditions(testToRender.Reasoning).length)
  //     ) : ( // 'M-Proc'
  //         testToRender.Type === 'M-Proc' ? (
  //           sortedConditions(testToRender.data.Reasoning)
  //               .slice(1, sortedConditions(testToRender.data.Reasoning).length)
  //         ) : {
  //             // 'Mole-Tracking'
  //           }
  //       )
  //   ) : {}

  // const handleWirePerson = () => {
  //   if (props.currentPatient && props.currentPatient.Sex === 'Female') {
  //     if (test && test.Front) {
  //       return womanFront
  //     } else {
  //       return womanBack
  //     }
  //   } else {
  //     if (test && test.Front) {
  //       return manFront
  //     } else {
  //       return manBack
  //     }
  //   }
  // }

  // const onClickSendFollowUp = (message, isHighConcern) => {
  //   setIsLoading(true)
  //   const userId = firebase.auth().currentUser.uid
  //   const time = Date.now()
  //   const concern = isHighConcern ? 'malignant' : 'benign'
  //   const dataToSend = {
  //     case: concern,
  //     patient_email: props.currentPatient.Email,
  //     physician_id: userId,
  //     patient_id: props.currentPatient.ID,
  //     notes: message,
  //     test_time: time,
  //     org_id: props.userData.Membership.Organization,
  //     test_id: props.currentTestSet ? props.currentTestSet.Id : props.currentTest.Id,
  //   }
  //   const jsonData = JSON.stringify(dataToSend)
  //   fetch(process.env.REACT_APP_URL + 'cds-patient-contact', {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: jsonData,
  //   })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setIsLoading(false)
  //         setContactPatient(false)
  //         props.storePayload({
  //           userMessage: `Success! An email has been sent to this patient.`,
  //           notificationType: 2,
  //           notificationIcon: 'check',
  //         })
  //       })
  //       .catch((error) => {
  //         setIsLoading(false)
  //         console.error('Error:', error)
  //         setContactPatient(false)
  //         props.storePayload({
  //           userMessage: 'There was an error sending an email to this patient. Please try again and contact Lazarus for support if this problem persists.',
  //           notificationType: 3,
  //           notificationIcon: 'warning',
  //         })
  //       })
  // }

  // const changeTest = (isForward) => {
  //   let mProcTestIndex = testSetIndex
  //   if (isForward) {
  //     mProcTestIndex++
  //   } else {
  //     mProcTestIndex--
  //   }
  //   // const mProcTest = props.currentTestSet.TestSets[0].Tests[mProcTestIndex]
  //   // console.log('mproctest', mProcTest)
  //   // setTest(mProcTest)
  //   // setTestSetIndex(mProcTestIndex)
  //   // props.storeValue('currentTest', mProcTest)
  // }

  const arePatientNotes = props.currentTestSet && props.currentTestSet.TestSets[0].Notes && props.currentTestSet.TestSets[0].Notes.length

  return (
    <div className='mole-tracking-test-page test-page'>
      {isGalleryView && <Gallery closeGallery={closeGallery} />}
      {isLoading || props.isLoading ? <FullLoadingScreen /> : (
        <Fragment>
          {redirect ? <Redirect to={'/'} /> : (
            <Fragment>
              {isReviewModal &&
                <Modal
                  confirmationFunc={confirmReview}
                  cancelationFunc={() => setIsReviewModalShowing(false)}
                  confirmationText={'Yes'}
                  cancelationText={'Not at this time'}
                  message={'This patient has requested a review of their timeline. Would you like to mark this timeline as reviewed? They will be notified through Lazarus Patient Portal.'}
                  image={check}
                  time={Helpers.timeStampToTime(Date.now())}
                  isVisible={isReviewModalShowing}
                />
              }
              <Fragment>
                {!contactPatient && test && testData &&
                <Fragment>
                  {/* {!isTestUpload &&
                    <FloatingActionButton
                      onClick={() => {
                        setIsTestUpload(false)
                        props.storePayload({
                          isFabOpen: true,
                          isFabShowing: !props.isFabShowing,
                        })
                      }}
                      isX={props.isFabShowing}
                    />
                  } */}
                  {/* <Link to={`/patient/${patientId}`}> */}
                  {patient && <PatientHeaderContainer patient={patient} />}
                  <main id='first-screen'>
                    <aside>
                      <AsideDiv
                        text='Test Date'
                        number={Helpers.timeStampToDate(props.currentTest.Tests[testSetIndex].DateTaken)}
                        type={1}
                        switchText={Helpers.timeStampToTime(testData.DateTaken)}
                      />
                      <AsideDiv
                        text='Location'
                        number={test.MoleLocation || 'N/A'}
                        type={2}
                        image={accuracy}
                        style={{backgroundColor: 'white'}}
                      />
                      {/* <AsideDiv
                        text='AI'
                        number='Heat Map'
                        type={2}
                        image={blockGreen}
                        style={asideDivStyle}
                        switch={true}
                        onClick={() => setHeatMap(!heatMap)}
                        //  Hack to allow funcitonality from clicking
                        //  both switch and AsideDiv
                        //  onClick reverses switch, onChange keeps it the same
                        onChange={() => setHeatMap(heatMap)}
                        isSelected={heatMap}
                      /> */}
                      <AsideDiv
                        text='Test'
                        number='Flag Test'
                        type={2}
                        image={flagImg}
                        style={asideDivStyle}
                        switch={true}
                        onClick={() => {
                          setFlag(!flag)
                          updateTest('Flag', !flag)
                        }}
                        /*
                          Hack to allow funcitonality from clicking
                          both switch and AsideDiv
                          onClick reverses switch, onChange "keeps it the same"
                        */
                        onChange={() => {
                          setFlag(flag)
                          updateTest('Flag', flag)
                        }}
                        isSelected={flag}
                      />
                    </aside>
                    <div className='lesion-div'>
                      <img
                        className='lesion-divImg'
                        onClick={() => setIsGalleryView(true)}
                        src={heatMap ? testData.HeatMapRef : testData.Tests[testSetIndex].ImageRef}
                        alt='lesion'
                      />
                    </div>
                    <div className='moleTrackingCarousel'>
                      <div className='moleTrackingCarousel-image-nav'>
                        <img
                          onClick={() => setTestSetIndex(0)}
                          className={(testSetIndex === 0) ? 'moleTrackingCarousel-images-border' : 'moleTrackingCarousel-images'}
                          src={testData.Tests[0].ImageRef}
                        />
                        <div className='moleTrackingCarousel-middle'>
                          {
                            props.currentTest.Tests.map((test, i) => {
                              if (i === 0 || i === (props.currentTest.Tests.length - 1)) {
                                return
                              } else {
                                return (
                                  <img
                                    onClick={() => setTestSetIndex(i)}
                                    className={(testSetIndex === i) ? 'moleTrackingCarousel-images-border' : 'moleTrackingCarousel-images'}
                                    key={i}
                                    src={testData.Tests[i].ImageRef}
                                  />
                                )
                              }
                            })
                          }
                        </div>
                        {props.currentTest.Tests.length > 1 &&
                          <img
                            onClick={() => setTestSetIndex(props.currentTest.Tests.length - 1)}
                            className={(testSetIndex === (props.currentTest.Tests.length - 1)) ? 'moleTrackingCarousel-images-border' : 'moleTrackingCarousel-images'}
                            src={testData.Tests[props.currentTest.Tests.length - 1].ImageRef}
                          />
                        }
                      </div>
                      {props.currentTest.Tests &&
                        <div className='moleTrackingCarousel-nav'>
                          <div className='arrow-div-first'>
                            {props.currentTest.Tests && testSetIndex > 0 &&
                              /*  Link (for unknown reason) updates url but doesn't rerender page. Clicking the link triggers changeTest()
                              and updates test (state) from the current test set
                              */
                              <Link to={`/patient/${props.currentPatient.ID}/moleTrackingTest/${props.currentTest.Id}/${parseInt(testSetIndex) - 1}`}>
                                <img src={leftArrowCircle} onClick={() => setTestSetIndex(parseInt(testSetIndex) - 1)} alt='back' />
                              </Link>
                            }
                          </div>
                          <p>{`${(parseInt(testSetIndex) + 1).toString()}/${props.currentTest.Tests.length}`}</p>
                          <div className='arrow-div-last'>
                            {props.currentTest.Tests && testSetIndex < (props.currentTest.Tests.length - 1) &&
                              <Link to={`/patient/${props.currentPatient.ID}/moleTrackingTest/${props.currentTest.Id}/${parseInt(testSetIndex) + 1}`}>
                                <img src={rightArrowCircle} onClick={() => setTestSetIndex(parseInt(testSetIndex) + 1)} alt='forward' />
                              </Link>
                            }
                          </div>
                        </div>
                      }
                    </div>
                    <div className='results-notes-div'>
                      <div>
                        <div className={arePatientNotes ? 'notes-div patient-notes-div relative' : 'notes-div relative'}>
                          <div className='flex justify-space-between'>
                            <p>Notes</p>
                            <img src={notesImg} alt='' />
                          </div>
                          {testData.Notes &&
                            <div className='patient-notes'>
                              <p>{`Overall Patient Notes: ${testData.Notes}`}</p>
                            </div>
                          }
                          {testData.Tests[testSetIndex].Notes &&
                            <div className='patient-notes'>
                              <p>{`Individual Notes: ${testData.Tests[testSetIndex].Notes}`}</p>
                            </div>
                          }
                          <textarea
                            maxLength='500'
                            onChange={onChangeNotes}
                            id='mole-tracking-physician-notes'
                            className={
                              isTyping ||
                              (notes && notes.length && !Helpers.isStringValid(notes)) ?
                              'isTyping' :
                              'isNotTyping'
                            }
                            ref={notesRef}
                          />
                          {isTyping && (
                            <Loader style={{height: '2em', bottom: '0.75em', left: 0}} />
                          )}
                        </div>
                      </div>
                      <div>
                        <FloatingButton
                          image={grid}
                          style={{
                            marginLeft: '1em',
                            padding: '1em',
                            boxSizing: 'border-box',
                            position: 'fixed',
                            right: '1em',
                          }}
                          onClick={() => {
                            setIsGalleryView(true)
                          }}
                          label=''
                        />
                      </div>
                    </div>
                    {/* <div className='contact-button-div'>
                  <Button
                    text='Contact Patient'
                    type={1}
                    onClick={() => setContactPatient(true)}
                  />
                </div> */}
                  </main>
                  {props.isFabOpen &&
                    <div
                      className={
                        isTestUpload ? 'test-upload-container' :
                          props.isFabShowing ? 'menu slideInLeft' : 'menu slideOutRight'
                      }
                    >
                      {/* {test && test.Type === 'M-Proc' &&
                  <FloatingButton
                    text='&#128424;'
                    style={{marginBottom: '0.5em'}}
                  >
                    {isPdfReady &&
                      <PDFDownloadLink
                        document={
                          <TestPdf
                            physicianInfo={props.userData.Info}
                            physicianMessage={test.PhysicianMessage || null}
                            patient={props.currentPatient}
                            wirePerson={handleWirePerson()}
                            patientBirthday={Helpers.formatDate(props.currentPatient.Birthday)}
                            patientNotes={test && test.Type === 'M-Proc' ? test.TestSets[0].Notes : null}
                            testDate={Helpers.formatDate(test && test.DateTaken.slice(0, 10))}
                            testSet={test.testSets[0]}
                            test1Image={test.testSets[0].Tests[0].data.ImageRef}
                            isEmailSent={test ? test.IsEmailSent : null}
                            decision={'N/A'}
                            testSetId = {test.testSets[0].Id && test.testSets[0].Id.slice(0, test.testSets[0].Id.indexOf('-'))}
                          />
                        }
                        fileName={`Dm_${this.formatFileNameDate(test.testSets[0].Tests[0].data.DateTaken.slice(0, 10))}_${props.currentPatient.Name}_${props.userData.Info.Name}.pdf`}
                        className="link"
                      >
                        {({blob, url, loading, error}) => {
                          return (!loading && blob && url &&
                          <div id="pdf-ready"className="btn-div2 pdf-btn-hidden">
                          </div>
                          )
                        }}
                      </PDFDownloadLink>
                    }
                  </FloatingButton>
                } */}
                      {/* {test && test.Type === 'Local' &&
                  <FloatingButton
                    text='&#128424;'
                    style={{marginBottom: '0.5em'}}
                    onClick={() => {
                      console.log('setting pdf ready', isPdfReady)
                      setIsPdfReady(true)
                    }}
                  >
                  </FloatingButton>
                } */}
                      {/* {isPdfReady &&
                  <PDFDownloadLink
                    document={
                      <LocalTestPdf
                        physicianInfo={props.userData.Info}
                        physicianMessage={test.PhysicianMessage || null}
                        patient={props.currentPatient}
                        wirePerson={handleWirePerson()}
                        patientBirthday={Helpers.formatDate(props.currentPatient.Birthday)}
                        testDate={Helpers.timeStampToDate(test.DateTaken)}
                        test1Image={test.ImageRef}
                        isEmailSent={test ? test.IsEmailSent : null}
                        decision={'N/A'}
                        testId={test.id}
                        test={test}
                        imageRef={test.ImageRef}
                      />
                    }
                    fileName={`Dm_${formatFileNameDate(test.DateTaken.toString().slice(0, 10))}_${props.currentPatient.Name}_${props.userData.Info.Name}.pdf`}
                    className="link"
                  >
                    {({blob, url, loading, error}) => {
                      return (!loading && blob && url &&
                      <div id="pdf-ready" className="btn-div2 pdf-btn-hidden">
                      </div>
                      )
                    }}
                  </PDFDownloadLink>
                } */}
                      {/* <FloatingButton
                  text='P&#10552;'
                  isP={true}
                  style={{marginBottom: '0.5em'}}
                  onClick={() => setIsTestRequest(!isTestRequest)}
                /> */}
                      <FloatingButton
                        text='QR'
                        style={{marginBottom: '0.5em'}}
                        onClick={() => {
                          setQrCode(!qrCode)
                          closeFab()
                        }}
                        label='Generate QR code'
                      />
                      <ImageUpload
                        isTest={true}
                        onClick={toggleIsTestUpload}
                        closeFab={closeFab}
                      />
                    </div>
                  }
                </Fragment>
                }
              </Fragment>
            </Fragment>
          )}
        </Fragment>
      )}
      {qrCode &&
        <Fragment>
          <div className='gray-screen' onClick={() => setQrCode(!qrCode)} />
          <div className='qr-code-modal'>
            <QRCode className="qr" size={256} value={Helpers.qrLink(patientId, props.currentPatient.ID, props.userData.Membership.Organization)} />
            <Button
              text='Close'
              type={2}
              onClick={() => setQrCode(!qrCode)}
            />
          </div>
        </Fragment>
      }
      {isPatientClicked &&
        <div
          className='patient-info-container'
          onClick={() => setIsPatientClicked(false)}
        >
          <div className='patient-info'>
            <Input
              disabled={true}
              label='Phone'
              value={patient.Phone}
              style={{width: '50%'}}
            />
            <Input
              disabled={true}
              label='Email'
              value={patient.Email}
              style={{width: '50%'}}
            />
          </div>
          {/* <div className='btn-container'>
            <Button
              type={2}
              text='Cancel'
            />
            <Button
              type={1}
              text='Merge To Selected'
            />
          </div> */}
        </div>
      }
      {/* {contactPatient && patient && testId &&
        <ContactPatient
          patient={patient}
          testId={testId}
          isTestRequest={false}
          test={props.currentTestSet || props.currentTest}
          cancel={() => setContactPatient(false)}
          onClickSendFollowUp={onClickSendFollowUp}
          testSetIndex={testSetIndex || null}
        />
      } */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  userData: state.firebaseReducer.userData,
  path: state.userReducer.path,
  patientsObj: state.userReducer.patientsObj,
  patientsData: state.userReducer.patientsData,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  currentTestSet: state.userReducer.currentTestSet,
  qrLink: state.userReducer.qrLink,
  isLoading: state.userReducer.isLoading,
  isFabShowing: state.userReducer.isFabShowing,
  isFabOpen: state.userReducer.isFabOpen,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, fetchPatientData},
)(MoleTrackingTest)


