import firebase from 'firebase/app'

const Helpers = {
  async postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  },
  time() {
    const now = new Date()
    const hour = now.getHours()
    const min = now.getMinutes()
    return `${hour}:${min}`
  },

  isPossibleMelanoma(test, testSetIndex) { // and Result !== Melanoma
    const melanomaThreshold = 20
    let testData
    if (test.Type === 'Local') {
      testData = test
    } else if (test.Type === 'M-Proc') {
      testData = test.TestSets[0].Tests[testSetIndex].data
    } else {
      return false
    }
    const isMelanoma = testData.Results === 'Melanoma'
    const melanomaObj = testData.Reasoning.filter((condition) => condition.Name === 'Melanoma')
    if (!isMelanoma && melanomaObj[0].Confidence >= melanomaThreshold) {
      return true
    } else {
      return false
    }
  },

  isMelanomaOrIsPossibleMelanoma(test) {
    if (test.Type === 'Mole-Tracking') {
      return false
    }
    const isMelanoma =
      test.Type === 'Local' ?
      test.Results === 'Melanoma' :
      test.data.Results === 'Melanoma'
    return isMelanoma || this.isPossibleMelanoma(test)
  },

  qrLink(patientId, patientID2, orgID) {
    return (
      process.env.REACT_APP_QR_URL + '/?doc=' +
      firebase.auth().currentUser.uid +
      '&patient=' + patientId +
      '&patient2=' + patientID2 +
      '&org=' + orgID +
      // '&patient2=' + 123 +
      '&date=' + Math.floor(Date.now()/1000)
    )
  },

  copyToClipBoard(copyText, setIsLinkCoppied) {
    navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        /* write to the clipboard now */
        navigator.clipboard.writeText(copyText).then(() => {
          /* clipboard successfully set */
          setIsLinkCoppied(true)
        }, function() {
          /* clipboard write failed */
          setIsLinkCoppied(false)
        })
      }
    })
  },

  filterByName(arr, searchVal) {
    if (!searchVal) {
      return arr
    }
    return arr.filter((val) => {
      if (val.Info) { // For Settings searchbar
        return val.Info.Name.toLowerCase().includes(searchVal.toLowerCase())
      }
      return val.Name.toLowerCase().includes(searchVal.toLowerCase())
    })
  },

  isStringValid(str) {
    return (
      str &&
      str !== '' &&
      str.split('').some((char) => char !== ' ')
    )
  },

  isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },


  isNumeric(value) {
    return (/^\d+$/.test(value))
  },

  isBirthdateNotValid(patientData) {
    const birthDateArray = patientData.birthDate.split('-')
    const birthdateString =
      `${birthDateArray[0]}-${birthDateArray[1]}-${birthDateArray[2]}`
    const birthDateObj = new Date(birthdateString)
    const birthDateTimeStamp = birthDateObj ? birthDateObj.getTime() : null
    const now = Date.now()
    return (
      birthDateArray.length != 3 ||
      !this.isNumeric(birthDateArray[0]) ||
      !this.isNumeric(birthDateArray[1]) ||
      !this.isNumeric(birthDateArray[2]) ||
      birthDateArray[0].toString().length != 4 ||
      birthDateArray[1].toString().length > 2 ||
      parseInt(birthDateArray[2]).toString().length > 2 ||
      !birthDateTimeStamp ||
      birthDateTimeStamp >= now
    )
  },

  isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  handleNewPatientInputs(patientData) {
    let userMessage
    if (!this.isStringValid(patientData.firstName) || !this.isStringValid(patientData.lastName)) {
      userMessage = 'Please enter the full name of the patient'
    } else if (!this.isStringValid(patientData.email)) {
      userMessage = 'Please enter email address'
    } else if (!this.isEmailValid(patientData.email)) {
      userMessage = 'Please enter a valid email address'
    } else if (!patientData.birthDate || patientData.birthDate === '') {
      userMessage = 'Please enter the birthday of the patient'
    } else if (this.isBirthdateNotValid(patientData)) {
      userMessage = 'Please enter the birthday in the correct format'
    } else if (!patientData.sex || patientData.sex === '') {
      userMessage = 'Please select the sex of your patient'
    } else if (patientData.phone && !this.isNumeric(patientData.phone)) {
      userMessage = 'Please make sure phone number only contains numbers'
    } else {
      userMessage = 'Valid entry'
    }
    return userMessage
  },

  isNewPatientValid(patientData) {
    if (this.handleNewPatientInputs(patientData) === 'Valid entry') {
      return true
    } else {
      return false
    }
  },

  timeStampToDate(timestamp) {
    const dateObj = new Date(timestamp)
    return (
      dateObj.getMonth() + 1 +
      '/' + dateObj.getDate() +
      '/' + dateObj.getFullYear()
    )
  },

  timeStampToMonth(timestamp) {
    const date = new Date(timestamp)
    const monthNum = date.getMonth()
    switch (monthNum) {
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'
    }
  },

  timeStampToYear(timestamp) {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    return year
  },

  timeStampToTime(timestamp) {
    const dateObj = new Date(timestamp)
    const hours = dateObj.getHours().toString()
    const minutes = dateObj.getMinutes().toString()
    const formattedHours = hours.length === 2 ? hours : '0' + hours
    const formattedMinutes = minutes.length === 2 ? minutes : '0' + minutes
    return (
      `${formattedHours}:${formattedMinutes}`
    )
  },

  formatDate(str) {
    if (str) {
      const dashB = str.slice(5, str.length) + '/' + str.slice(0, 4)
      return dashB.replace('-', '/')
    } else {
      return ''
    }
  },

  redConditions: [
    'Melanoma',
  ],

  orangeConditions: [
    'Basal cell carcinoma',
  ],

  yellowConditions: [
    'Actinic keratoses',
  ],

  /* Example return {Name: 'Melanoma', Confidence: 0.3} */
  sortedReasoning(test, reasoning) {
    /* Highest confidence to lowest confidence */
    const sortedReasoning = reasoning.sort((a, b) => {
      return b.Confidence - a.Confidence
    })
    return sortedReasoning.map((condition) => {
      const riskLevel = this.returnConditionRiskLevel(condition.Name)
      return (
        {
          ...condition,
          Risk: riskLevel,
        }
      )
    })
  },

  /*  If 2 or more tests have identical results, return (example)
      {
        topCondition: 'Benign keratosis',
        confidenceRange: [70, 90],
        //  if any test's top result was red/orange level
            or if any red/orange level conditions pass threshold
            in any test's differential, isHighConcern = true
        isHighConcern: true/false,
      }
      else, return
      {
        topCondition: 'Beign keratosis,
        confidence: [60]
        isHighConcern: true/false,
      }
  */
  returnTopCondition(tests) {
    /*  If any red/orange level condtion's confidence is greater than
        high concern threshold, set isHighConcern to true
        (For full differential on every test)
    */
    const redThreshold = 20 // high concern threshold
    const orangeThreshold = 30 // high conern threshold
    const conditionsObj = {}
    let topCondition // If 2 or more tests results match
    let confidenceRange
    let isHighConcern
    let isPossibleMelanoma
    let melanomaConfidence = 0
    let melanomaTests = 0

    /* If no duplicate results, top confidence test */
    let topConfidenceTest = {Results: 'N/A', Confidence: 0}

    /* set isHighConcern true if results are in red/organge conditions */
    const isRedCondition = (result) => {
      return this.redConditions.some((condition) => condition === result)
    }
    const isOrangeCondition = (result) => {
      return this.orangeConditions.some((condition) => condition === result)
    }

    tests.forEach((test) => {
      /* Check if all top results are melanoma */
      if (test.data.Results === 'Melanoma') {
        melanomaTests++
      }
      /*  Check through differentials for high concern
          conditions passing threshold
      */
      test.data.Reasoning.forEach((condition) => {
        if (isRedCondition(condition.Name) &&
            condition.Confidence > redThreshold) {
          isHighConcern = true
          isPossibleMelanoma = true // added after the fact: only red condition
          if (condition.Confidence > melanomaConfidence) {
            melanomaConfidence = condition.Confidence
          }
        }
        if (isOrangeCondition(condition.Name) &&
            condition.Confidence > orangeThreshold) {
          isHighConcern = true
        }
      })

      /*  Check if top result is red/orange level concern and set isHighConern
          Not necessary if checking through differential unless extra level
          of severity is added
      */
      // if (isRedCondition(test.results) || isOrangeCondition(test.results)) {
      //   isHighConcern = true
      // }

      /* Check for 2 or 3 of the same condition as top result */

      /*  Memoization-if condition is in conditionsObj, push confidence to it,
          else, instantiate an array with the confidence as it's only value
      */
      if (conditionsObj[test.data.Results]) {
        topCondition = test.data.Results
        conditionsObj[test.data.Results].push(test.data.Confidence)
        const conf1Arr = conditionsObj[test.data.Results]
        const conf2 = test.data.Confidence
        const confArr = [...conf1Arr, conf2]
        const lowToHighConfArr = confArr.sort((a, b) => a - b)
        const decimalConfRange = [lowToHighConfArr[0], lowToHighConfArr[lowToHighConfArr.length - 1]]
        confidenceRange = [decimalConfRange[0] * 100, decimalConfRange[1] * 100]
      } else if (isPossibleMelanoma) {
        topCondition = 'Possible Melanoma'
      } else {
        conditionsObj[test.data.Results] = [test.data.Confidence]
        if (test.data.Confidence > topConfidenceTest.Confidence) {
          topConfidenceTest = test.data
        }
      }
    })

    if (melanomaTests === 3) {
      topCondition = 'Melanoma'
    }

    if (isPossibleMelanoma) {
      return {
        topCondition: topCondition, // Possible Melanoma
        confidence: melanomaConfidence,
        isHighConcern,
        isPossibleMelanoma,
      }
    } else if (!topCondition || (confidenceRange[0] === confidenceRange[1])) { // Single confidence value
      return {
        topCondition: topConfidenceTest.Results,
        confidence: topConfidenceTest.Confidence * 100,
        isHighConcern,
        isPossibleMelanoma,
      }
    } else { // Confidence Range
      return {
        topCondition,
        confidenceRange,
        isHighConcern,
        isPossibleMelanoma,
      }
    }
  },

  returnConditionRiskLevel(condition) {
    if (this.redConditions.some((hrc) => hrc === condition)) {
      return 'red'
    } else if (this.orangeConditions.some((mrc) => mrc === condition)) {
      return 'orange'
    } else if (this.yellowConditions.some((mrc) => mrc === condition)) {
      return 'yellow'
    } else {
      return 'green'
    }
  },

  /* Gives red test is possible melanoma */
  returnOverallRiskLevel(test, condition) {
    if (this.redConditions.some((hrc) => hrc === condition)) {
      return 'red'
    }
    if (this.isMelanomaOrIsPossibleMelanoma(test)) {
      return 'red'
    } else if (this.orangeConditions.some((mrc) => mrc === condition)) {
      return 'orange'
    } else if (this.yellowConditions.some((mrc) => mrc === condition)) {
      return 'yellow'
    } else {
      return 'green'
    }
  },

  toggleTyping(timeOfType, callback, pauseLength = 2000) {
    setTimeout(() => {
      const now = Date.now()
      if ((now - timeOfType) >= pauseLength) {
        callback()
      }
    }, pauseLength);
    return false
  },

  loadImageCB(imgCanvas) {
    const blobPromise = new Promise((resolve, reject) => {
      if (imgCanvas.height < 480 || imgCanvas.width < 480) {
        // alert("Warning: Image should be at least 480 x 480 pixels")
        this.setState({tooSmall: true})
      } else {
        this.setState({tooSmall: false})
      }
      imgCanvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty')
          return;
        }
        blob.name = 'fileName'
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve([this.fileUrl, blob])
      }, 'image/jpeg')
    });

    blobPromise
        .then((imageArr) => {
          this.setState({
            front: !this.state.front,
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
            isImageCropped: false,
            showCollage: false,
          })
        })
  },

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const maxWidth = 960

    const shrinkRatio = maxWidth / image.naturalWidth
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio)
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio)
    } else {
      canvas.width = Math.floor(crop.width * scaleX)
      canvas.height = Math.floor(crop.height * scaleY)
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth = 480 / scaleX / shrinkRatio
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX * shrinkRatio,
          crop.height * scaleY * shrinkRatio,
      );
    } else {
      minWidth = 480 / scaleX
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY,
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
        }
        blob.name = fileName
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve([this.fileUrl, blob])
      }, 'image/jpeg')
    })
  },

  updateLastUpdate(firestore, orgId, patientId) {
    firestore
        .collection('LCDS')
        .doc('Something')
        .collection('Organizations')
        .doc(orgId)
        .collection('Patients')
        .doc(patientId)
        .update({
          'LastUpdate': Date.now(),
        })
        .catch(() => console.log('error updating LastUpdate'))
  },
}

export default Helpers

