import types from '../types.js'

/*  Takes an array of patient Id's from 'Something/Organization/Patients' and
    fetches the patient data (tests) from 'Something/Patients
    Stores {patientId: {...res}} as patientsData in userReducer
*/
export const fetchPatientData =
(firestore, patientIds, patientsData) => (dispatch) => {
  patientIds.forEach(async (patientId) => {
    const patientDoc = await
    firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .get()
        .catch(() => {
          console.log('Error finding patient')
          return 'error'
        })
    if (!patientDoc) {
      console.log('no patient doc')
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          userMessage: 'There was an error finding patient with Id: ' + patientId,
          notificationType: 2,
          notificationIcon: 'warning',
          isPatientDataFetched: 'no',
        },
      })
    }
    firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .collection('DermatologyTests')
        .onSnapshot((res) => {
          if (res.docs) {
            const patientTestDocs = res.docs
            const patientTests = patientTestDocs.map((doc) => {
              if (doc.data().Results) { // test has results (default behavior)
                return (
                  {...doc.data(), Id: doc.id}
                )
                // all of the remaining checks are for the edge case where
                // the test is missing results.
                // Sets results to the correct value if reasoning is present
                // Otherwise sets it to be 'Missing Results'
              } else if (doc.data().Reasoning && doc.data().Type !== 'M-Proc') {
                const reasoning = (doc.data().Reasoning).sort((a, b) => {
                  return b.Confidence - a.Confidence
                })
                return (
                  {...doc.data(), Id: doc.id, Results: reasoning[0].Name}
                )
              } else if (doc.data().Type === 'M-Proc') { // M-Proc
                const mProcData = {...doc.data(), Id: doc.id}
                doc.data().TestSets[0].Tests.forEach((mProcTest, count) => {
                  if (!mProcTest.data.Reasoning) {
                    return
                  }
                  const reasoning = (mProcTest.data.Reasoning).sort((a, b) => {
                    return b.Confidence - a.Confidence
                  })
                  mProcData.TestSets[0].Tests[count].data = {
                    ...mProcData.TestSets[0].Tests[count].data,
                    Results: reasoning[0].Name,
                  }
                })
                return mProcData
              } else { // has neither reasoning nor results (prevent crashing)
                return (
                  {...doc.data(), Id: doc.id, Results: 'Missing Results'}
                )
              }
            })
            const data = patientDoc.data()
            patientsData[patientId] = {tests: patientTests, data}
            // const patientsDataKeys = Object.keys(patientsData)
            /* After last test (check because async) */
            // if (patientIds.length === patientsDataKeys.length) {
            // console.log('patient data being stored', patientsData)
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                patientsData,
                isPatientDataFetched: 'yes',
                patientsDataFetchTime: Date.now(), // used to detect change to patientsData
              },
            })
          } else {
            console.log('error finding tests')
          }
        })
  })
}

