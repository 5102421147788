// Dependencies
import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";
import back from '../images/white/back.svg'

// Styles
import '../styles/TopNav.css'

/*
  Renders header with dynamic URI indicating user's location within app
*/
function TopNav(props) {
  const history = useHistory()
  const defaultPath = (
    <Fragment>
      <Link to='/'>
        <pre className='pre-link'>{`Home`}</pre>
      </Link>
      {/* {props.path && <pre>{` / ${props.path}`}</pre>} */}
    </Fragment>
  )

  const patientPath = props.currentPatient ? (
    <Fragment>
      {defaultPath}
      <Link to={`/patient/${props.currentPatient.ID}`}>
        <pre>{` / `}</pre>
        <pre className='pre-link'>{props.currentPatient.Name}</pre>
      </Link>
    </Fragment>
  ) : defaultPath

  const testSetPath = props.currentTestSet && props.currentPatient && props.currentTest ? (
    <Fragment>
      {patientPath}
      <Link to={`/patient/${props.currentPatient.ID}/test/${props.currentTestSet.TestSets[0].Id}`}>
        <pre>
          {` / `}
        </pre>
        <pre className='pre-link'>
          {props.currentTestSet.TestSets[0].Id}
        </pre>
      </Link>
    </Fragment>
  ) : defaultPath

  const testPath = props.currentPatient && props.currentTest ? (
    <Fragment>
      {patientPath}
      <Link to={`/patient/${props.currentPatient.ID}/${props.currentTest.Type === 'Mole-Tracking' ? 'moleTrackingTest' : 'test'}/${props.currentTest.Id}${props.currentTest.Type === 'Mole-Tracking' && '/0'}`}>
        <pre>
          {` / `}
        </pre>
        <pre className='pre-link'>
          {props.currentTest.Id}
        </pre>
      </Link>
    </Fragment>
  ) : defaultPath

  const path = () => {
    if (props.currentTestSet && props.currentTest && props.currentPatient) {
      return testSetPath
    } else if (props.currentTest && props.currentPatient) {
      return testPath
    } else if (props.currentPatient) {
      return patientPath
    } else {
      return defaultPath
    }
  }
  return (
    <nav className="top-nav">
      <div className="gray-square" onClick={() => history.goBack()}>
        <img src={back} alt='back' />
      </div>
      <div className="uri-div">
        {path()}
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => ({
  path: state.userReducer.path,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  currentTestSet: state.userReducer.currentTestSet,
})

export default connect(
    mapStateToProps,
)(TopNav)
