// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'

// Actions
import {storeValue} from '../actions/storeValue.js'

// Components
import {ZoomSlider} from '@anishp16/lazarus-cds'

// Images
import x from '../images/white/x.svg'

// Styles
import '../styles/Gallery.css'
import '../styles/ZoomView.css'
import '@anishp16/lazarus-cds/dist/index.css'

function ZoomView(props) {
  const [zoomValue, setZoomValue] = useState(100)
  const [width, setWidth] = useState()

  useEffect(() => {
    const body = document.getElementById('gallery-container')
    const em = parseFloat(getComputedStyle(body).fontSize)
    const bodyHeight = body.clientHeight - (6 * em)
    const zoomRatio = zoomValue / 100
    const minZoomRatio = .1
    const adjustedZoomRatio = zoomRatio > minZoomRatio ? zoomRatio : minZoomRatio
    setWidth(bodyHeight * adjustedZoomRatio)
  }, [zoomValue])

  const onChangeZoom = (event) => {
    setZoomValue(event.target.value * 2)
  }

  return (
    <div className='gallery-container zoomView-gallery-container' id='gallery-container'>
      <nav className='top-nav'>
        <div onClick={props.closeZoom} className='x-box'>
          <img src={x} />
        </div>
        {props.currentPatient && props.currentTest &&
          <div className="uri-div">
            <pre>
              {`${props.currentPatient.Name} > Test ID:  ${props.currentTestSet == null ? props.currentTest.Id : props.currentTestSet.Id}`}
            </pre>
          </div>
        }
      </nav>
      <body id='zoomView-body'>
        <div className='gallery-side'></div>
        <div className='under-nav'>
          <div className='view-toggle flex'>
          </div>
          <div className='zoom-slider-container'>
            <ZoomSlider
              onChangeZoom={onChangeZoom}
              value={zoomValue / 2}
            />
          </div>
        </div>
        <div className='zoomView-images-container'>
          {props.currentTestSet == null ? (
          <div className='zoomView-images'>
            <div className='image-carousel'>
              <img
                className='carousel-image'
                style={{
                  width: `${width}px`,
                  height: 'auto',
                }}
                src={props.currentTest.ImageRef} />
            </div>
          </div>
         ) : (
          <div className='zoomView-images-container'>
            <div
              id='m-proc-row'
              className='grid-images'
            >
              {props.currentTestSet.TestSets[0].Tests.map((image, i) => {
                console.log('image', image)
                return (
                  <div className='grid-image' key={i}>
                    <img
                      className='m-proc-image'
                      style={{
                        width: `${width}px`,
                        height: 'auto',
                      }}
                      src={image.data.ImageRef} />
                  </div>
                )
              })}
            </div>
          </div>
         )}
        </div>
      </body>
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  currentTestSet: state.userReducer.currentTestSet,
})

export default connect(mapStateToProps, { storeValue })(ZoomView)
