// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import Helpers from '../Helpers.js'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {withCookies} from 'react-cookie'
import queryString from 'query-string'
import manFront from '../images/wirePerson/manFront.jpg'
import manBack from '../images/wirePerson/manBack.jpg'
import womanFront from '../images/wirePerson/womanFront.jpg'
import womanBack from '../images/wirePerson/womanBack.jpg'

// Actions
import {createFirebaseApp} from '../actions/createFirebaseApp'
import {signIn} from '../actions/signIn'
import {storePayload} from '../actions/storePayload'
import {fetchOrgPatients} from '../actions/fetchOrgPatients'

// Components
import Auth from './Auth.js'
import Dashboard from './Dashboard'
import MProc from './MProc'
import WithNav from './WithNav'
import Patient from './Patient'
import Test from './Test'
import MoleTrackingTest from './MoleTrackingTest'
import AddPatient from './AddPatient'
import TestLink from './TestLink'
// import PatientLink from './PatientLink'
import TestRequest from './TestRequest'
import Settings from './Settings'
import {Notification} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import FullLoadingScreen from './FullLoadingScreen'

// Images
import Check from '../images/check.svg'
import Warning from '../images/warning.svg'

/*
  Renders components to routes
  Resize #root on resize event
  Creates firebase app on render
  Fetches organization patients
*/
function App(props) {
  const onClickNotification = () => {
    setIsNotificationVisible(false)
    setTimeout(() => {
      props.storePayload({
        userMessage: null,
        notificationType: null,
        notificationIcon: null,
      })
      setIsNotificationVisible(true)
    }, 300)
  }

  /* On component mount */
  useEffect(() => {
    const patientLinkStatus = queryString.parse(window.location.search).patient_link_status
    if (patientLinkStatus) {
      if (patientLinkStatus === 'linked') {
        props.storePayload({
          userMessage: 'A new patient has been linked to your account',
          notificationType: 2,
          notificationIcon: 'check',
        })
      } else if (patientLinkStatus === 'existing') {
        props.storePayload({
          userMessage: 'This patient is already linked to your account',
          notificationType: 2,
          notificationIcon: 'warning',
        })
      }
    }
    // resize root to fit window, create firebase
    const resize = () => {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const root = document.getElementById('root')
      root.style.height = `${windowHeight}px`
      root.style.width = `${windowWidth}px`
      const mains = document.getElementsByTagName('MAIN')
      if (mains.length) {
        const main = mains[0]
        if (main) {
          const em = parseFloat(getComputedStyle(main).fontSize)
          // main.style.height = `${windowHeight}px`
          main.style.width = `${windowWidth - (12.5 * em)}px`
          main.style.height = `${windowHeight - (9 * em)}px`
        }
      }
    }
    window.addEventListener('resize', resize)
    window.addEventListener('orientationchange', resize)
    resize()
    props.createFirebaseApp()
  }, [])

  const url = window.location.href
  const defaultIsInvitePath = !url || url.includes('invite')
  const [isLoading, setIsLoading] = useState(true)
  const [isActive, setIsActive] = useState('pending')
  const [isInvitePath, setIsInvitePath] = useState(defaultIsInvitePath)
  const [isNotificationVisible, setIsNotificationVisible] = useState(true)
  const {database, firestore, authStatus} = props
  const pathChange = useLocation()

  /* Close Fab and delete test upload data */
  const resetState = () => {
    props.storePayload({
      newTestData: {},
      isFabOpen: false,
      isFabShowing: false,
    })
  }

  useEffect(resetState, [pathChange])

  /*
    Once firebase app is created,
    Check for cookies,
    Log in with cookies
  */

  useEffect(async () => {
    const isDatabaseReady =
      database && Object.keys(database).length > 0
    if (!props.signedIn && isDatabaseReady && props.cookies) {
      const email = props.cookies.get('email')
      const password = props.cookies.get('password')
      if (email && password && !isInvitePath) {
        props.signIn(email, password, props.cookies, firestore)
      } else {
        await setIsLoading(false)
      }
    }
  }, [database])

  // useEffect(async () => {
  //   console.log('is invite path', isInvitePath)
  //   await setIsLoading(false)
  //   console.log('is loading?', isLoading)
  // }, [isInvitePath])

  /* Fetch org patients once signed in */
  useEffect(() => {
    if (props.signedIn && props.userData && props.userData.Membership && (!props.orgPatientList || !props.orgPatientList.length)) {
      props.fetchOrgPatients(
          props.firestore,
          props.userData.Membership.Organization,
      )
    }
  }, [props.signedIn])

  useEffect(() => {
    const isOrgData = props.orgData && Object.keys(props.orgData)
    const isOrgActive = isOrgData && props.orgData.Sub && props.orgData.Sub.isActive
    // if (isOrgData && props.orgData.Status === 'created') {
    //   alert('setting signed in false')
    //   props.storeValue('signedIn', false)
    // }
    if (isOrgData) {
      setIsActive(true)
      // if (isOrgActive) {
      //   setIsActive(true)
      // } else {
      //   setIsActive(false)
      // }
    }
  }, [props.orgData])

  /* After signIn attempt, set isLoading to false */
  useEffect(() => {
    if (isActive !== 'pending' && authStatus) {
      setIsLoading(false)
    }
  }, [authStatus, isActive])

  /* Redirects depending on signedIn status */
  function routeAccordingToAuth(path, component, isExact) {
    if (isInvitePath) { // Prevents redirect ?
      return (<div />)
    }
    return isLoading ? (
      <Route
        path={path}
        component={FullLoadingScreen}
      />
    ) : (
      props.signedIn ? (
        isExact ? ( // signed in exact path
          <Route
            exact path={path}
            render={(props) => (
              <WithNav {...props} component={isActive ? component : Settings} />
            )}
          />
        ) : ( // signed in, not exact path
          <Route
            path={path}
            render={(props) => (
              <WithNav {...props} component={isActive ? component : Settings} />
            )}
          />
        )
      ) : ( // not signed in
        isExact ? (
          <Route {...props} exact path={path} component={Auth} />
        ) : (
          <Route path={path} {...props} component={Auth} />
        )
      )
    )
  }

  useEffect(() => {
    if (props.notificationType === 2) {
      setTimeout(onClickNotification, 10000)
    }
  }, [props.notificationType])

  // Clear cookies on sign in failure
  useEffect(() => {
    if (props.authStatus && props.authStatus === 'failure') {
      props.cookies.remove('email', {path: '/'})
      props.cookies.remove('password', {path: '/'})
      setIsLoading(false)
    }
  }, [props.authStatus])

  return (
    <Fragment>
      {props.userMessage &&
        <Notification
          type={props.notificationType || 1}
          image={props.notificationIcon === 'check' ? Check : Warning}
          message={props.userMessage}
          time={Helpers.timeStampToTime(Date.now())}
          isVisible={isNotificationVisible}
          onClick={onClickNotification}
        />
      }
      {routeAccordingToAuth('/', Dashboard, true)}
      {routeAccordingToAuth('/patient/:patientId', Patient, true)}
      {routeAccordingToAuth('/patient/:patientId/test/:testId/:mProcId?', Test, false)}
      {routeAccordingToAuth('/patient/:patientId/moleTrackingTest/:testId/:mTrackId?', MoleTrackingTest, false)}
      {routeAccordingToAuth('/add', AddPatient, true)}
      {/* {routeAccordingToAuth('/test_link', TestLink, true)} */}
      {routeAccordingToAuth('/mproc', MProc, true)}
      {routeAccordingToAuth('/testRequest', TestRequest, false)}
      {routeAccordingToAuth('/settings', Settings, true)}
      <Route
        exact path='/invite/:orgId'
        render={(props) => <Auth {...props} />}
      />
      {/* <Route
        exact path='/link/:key'
        render={(props) => <PatientLink {...props} />}
      /> */}
      {/* Hidden images to crop in Patient component */}
      <img
        className='invisible'
        id='invisible-manFront'
        src={manFront}
        alt='invisible man'
      />
      <img
        className='invisible'
        id='invisible-manBack'
        src={manBack}
        alt='invisible man'
      />
      <img
        className='invisible'
        id='invisible-womanFront'
        src={womanFront}
        alt='invisible woman'
      />
      <img
        className='invisible'
        id='invisible-womanBack'
        src={womanBack}
        alt='invisible man'
      />
      {props.isFabOpen && <div className={props.isFabShowing ? 'grow-circle giant-circle' : 'shrink-circle giant-circle'} />}
      {props.isFabShowing &&
      <div onClick={() => props.storePayload({isFabShowing: false})} className='white-screen' />
      }
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signedIn: state.userReducer.signedIn,
  authStatus: state.firebaseReducer.authStatus,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  notificationIcon: state.userReducer.notificationIcon,
  isFabOpen: state.userReducer.isFabOpen,
  isFabShowing: state.userReducer.isFabShowing,
  isLoading: state.userReducer.isLoading,
  patientCreated: state.userReducer.patientCreated,
})

export default withRouter(withCookies(connect(
    mapStateToProps,
    {createFirebaseApp, signIn, storePayload, fetchOrgPatients},
)(App)))
