// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import Helpers from '../Helpers.js'

// Actions
import {storeValue} from '../actions/storeValue'
import {storePayload} from '../actions/storePayload'
import {createPatient} from '../actions/createPatient'

// Components
import {Header, Input, DateInput, SexInput, Button} from '@anishp16/lazarus-cds'
import ImageUpload from './ImageUpload'
import NewPatientForm from './NewPatientForm'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/AddPatient.css'

// Images
import User from '../images/white/User.svg'
import add from '../images/white/Add.svg'
import notesImg from '../images/notes.svg'
import FullLoadingScreen from './FullLoadingScreen.js'

function AddPatient(props) {
  const textAreaRef = React.createRef()
  const [patientImage, setPatientImage] = useState(null)
  const [isDefaultMessageThere, setIsDefaultMessageThere] = useState(false)
  /*  isSummary is the state of the component after user has clicked
      Review...The title says Summary
  */
  const [isSummary, setIsSummary] = useState(false)
  const defaultTestRequestMessage =
    props.userData ? `Please complete your scheduled skin cancer self-assessment for ${props.userData.Info.Name}. To begin, open the link below, preferably on a mobile device.\r\n\r\nFor a skin cancer screening, please collect three images of your pigmented skin lesion and upload them through our portal. ${props.userData.Info.Name} will review them promptly and reach out to explain what you will need to do next.\r\n\r\nSincerely,\r\n${props.userData.Info.Name}` : ''
  /* Set default message on component mount */
  useEffect(() => {
    if (textAreaRef.current && !isDefaultMessageThere) {
      textAreaRef.current.value = defaultTestRequestMessage
      setIsDefaultMessageThere(true)
      props.storeValue('testRequestMessage', defaultTestRequestMessage)
    }
  }, [props.newPatient, textAreaRef])

  useEffect(() => {
    const {formattedNewPatient} = props
    if (formattedNewPatient) {
      const userId = firebase.auth().currentUser.uid
      const time = Date.now()
      const dataToSend = {
        patient_email: formattedNewPatient.Email,
        physician_id: userId,
        patient_id: formattedNewPatient.ID,
        notes: props.testRequestMessage,
        test_time: time,
        test_id: formattedNewPatient.ID,
        org_id: props.userData.Membership.Organization,
      }
      const jsonData = JSON.stringify(dataToSend)
      fetch(process.env.REACT_APP_URL + 'sendTestRequest', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      })
          .then((response) => {
            if (response.ok) {
              return response.json()
            }
          })
          .then((data) => {
            props.storePayload({
              userMessage: `Success! An email has been sent to this patient.
              You will receive an email notification once the patient completes the test.
              
              Once the test is completed by the patient, you can filter for this test through the "Awaiting Follow-up" filter on the Test Thumbnail Page`,
              notificationType: 3,
              notificationIcon: 'check',
              formattedNewPatient: null,
              testRequestMessage: null,
            })
            // setIsTestRequest(false)
          })
          .catch((error) => {
            console.error('Error:', error)
            props.storePayload({
              userMessage: 'There was an error sending an email to this patient. Please try again and contact Lazarus for support if this problem persists.',
              notificationType: 3,
              notificationIcon: 'warning',
              formattedNewpatient: null,
            })
            // setIsLoading(false)
            // setIsTestRequest(false)
          })
    }
  }, [props.formattedNewPatient])

  const validateForm = () => {
    const userMessage = Helpers.handleNewPatientInputs(props.newPatient)
    if (userMessage === 'Valid entry') {
      return true
    } else {
      props.storePayload({
        patientCreated: false,
        isLoading: false,
        responseMessage: userMessage,
        userMessage: userMessage,
        notificationType: 2,
        notificationIcon: 'warning',
      })
      return false
    }
  }

  const createPatientAccount = () => {
    const isTestRequest = props.isMprocRequest
    const isFormValid = validateForm()
    if (isFormValid) {
      props.createPatient(
          props.firestore,
          props.userData.Membership.Organization,
          {
            ...props.newPatient,
            image: props.personImageUrl,
          },
          isTestRequest,
          props.orgPatientsObj,
          props.orgPatientList,
          // {
          //   firstName: 'Miles',
          //   lastName: 'Davis',
          //   sex: 'male',
          //   birthDate: '1935-05-26',
          //   phone: 1234567890,
          //   email: 'eric@lazarus.enterprises',
          // },
      )
    }
  }

  const onClickSendOrCreate = () => {
    if (props.isMprocRequest) {
      if (isSummary) {
        createPatientAccount()
      } else {
        if (Helpers.isStringValid(props.testRequestMessage)) {
          if (Helpers.isNewPatientValid(props.newPatient)) {
            setIsSummary(true)
          } else {
            props.storePayload({
              userMessage: Helpers.handleNewPatientInputs(props.newPatient),
              notificationIcon: 'warning',
              type: 2,
            })
          }
        } else { // No message
          props.storePayload({
            userMessage: 'Please include a message for the patient.',
            notificationIcon: 'warning',
            type: 2,
          })
        }
      }
    } else {
      createPatientAccount()
    }
  }

  return (
    <div className='add-patient'>
      {props.patientCreated ?
      <Redirect to={props.isMprocRequest ? '/mproc' : '/'} /> :
      (
      <Fragment>
        {props.userData && props.userData.Info &&
          <Fragment>
            {props.isLoading ? <FullLoadingScreen /> :
            <Fragment>
              <Header
                image={props.userData.Info.ImageRef || User}
                name={props.userData.Info.Name}
                practice={props.userData.Info.Practice}
              />
              <main>
                <div className='add-image-div'>
                  <div>
                    {/* {patientImage ?
                      <img src={patientImage} alt='' /> :
                      <img src={add} alt='add image' />
                    } */}
                    <ImageUpload isPatient={true} isDisabled={isSummary} />
                  </div>
                </div>
                <div className='form-div'>
                  <h1>
                    {props.isMprocRequest ?
                      isSummary ?
                        'Test Request Review' :
                        'Request remote test from new patient':
                      'Create Patient Account'
                    }
                  </h1>
                  <NewPatientForm isSummary={isSummary} />
                </div>
                <div className='rightCol'>
                  <div className='rightCol-top' />
                  <div>
                    {props.isMprocRequest &&
                      <Fragment>
                        <div className='message-div flex justify-space-between'>
                          <p>Message To Patient</p>
                          <img src={notesImg} alt='' />
                        </div>
                        <div className='text-area-container'>
                          <textarea
                            ref={textAreaRef}
                            onChange={(event) => {
                              props.storeValue('testRequestMessage', event.target.value)
                            }}
                          />
                          {isSummary &&
                          <div className='text-area-overlay'>
                            <Button
                              text='Edit'
                              type={2}
                              onClick={() => setIsSummary(false)}
                              width={'calc(100% - 2em)'}
                              style={{
                                position: 'absolute',
                                bottom: '1em',
                                width: 'calc(100% - 2em)',
                                left: '1em',
                                zIndex: 3,
                              }}
                            />
                          </div>
                          }
                        </div>
                      </Fragment>
                    }
                  </div>
                  <Button
                    text={
                      props.isMprocRequest ?
                        isSummary ?
                          'Send Test Request' :
                          'Send' :
                        'Create'

                    }
                    type={1}
                    onClick={onClickSendOrCreate}
                  />
                </div>
              </main>
            </Fragment>
            }
          </Fragment>
        }
      </Fragment>)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  newPatient: state.userReducer.newPatient,
  isMprocRequest: state.userReducer.isMprocRequest,
  userData: state.firebaseReducer.userData,
  patientCreated: state.userReducer.patientCreated,
  personImageUrl: state.userReducer.personImageUrl,
  isLoading: state.userReducer.isLoading,
  patientCreated: state.userReducer.patientCreated,
  formattedNewPatient: state.userReducer.formattedNewPatient,
  testRequestMessage: state.userReducer.testRequestMessage,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  orgPatientList: state.userReducer.orgPatientList,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, createPatient},
)(AddPatient)
