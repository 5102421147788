import firebase from 'firebase/app'
import types from '../types.js'

export const fetchOrgPatients = (firestore, orgId) => (dispatch) => {
  /*
    '.onSnapshot' will sometimes resolve before fetching all of the
    documents in the collection. However '.get' will get the full
    collection when it resolves. The callback to onSnapshot
    fetches the collection size, then data is only dispatched once
    the number of documents in the snapshot response equals the
    length found in fetchCollectionSize.
  */
  let collectionSize = 0
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .collection('Patients')
      .onSnapshot((res) => {
        if (res) {
          fetchCollectionSize()
              .then((cs) => {
                collectionSize = cs
                const orgPatientsDocsArr = res.docs
                const orgPatientsObj = {}
                let orgPatientList = orgPatientsDocsArr.map((patientDoc) => {
                  const patient = patientDoc.data()
                  orgPatientsObj[patient.ID] = patient
                  return patient
                })
                orgPatientList = orgPatientList.sort((a, b) => {
                  return b.LastUpdate - a.LastUpdate
                })
                if (orgPatientList.length === collectionSize) {
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      orgPatientList,
                      orgPatientsObj,
                    },
                  })
                }
              })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              orgPatientList: null,
              orgPatientsObj: null,
            },
          })
        }
      }, (error) => console.log(error))


  async function fetchCollectionSize() { // fetch length of collection
    const res = await firestore.collection('LCDS')
        .doc('Something')
        .collection('Organizations')
        .doc(orgId)
        .collection('Patients')
        .get()
    return res.size
  }
}
