import { findByDisplayValue } from '@testing-library/react'
import firebase from 'firebase/app'
import types from '../types'
import Helpers from '../Helpers'

export const createPatient = (
    firestore, organization, patientData, isTestRequest, orgPatientsObj, orgPatientList,
) => (dispatch) => {
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isLoading: true,
    },
  })

  let newRefKey // Set to res.id
  // Add date of creation
  firestore.collection('LCDS').doc('Something').collection('Patients').add({
    Created: Date.now(),
  })
      .then((res) => { // Add patient data
        newRefKey = res.id
        const newPatientData = {
          Birthday: patientData.birthDate,
          Created: Date.now(),
          CreatedBy: firebase.auth().currentUser.uid,
          ID: newRefKey,
          ImageRef: patientData.image ? patientData.image : null,
          LastUpdate: Date.now(),
          Name: `${patientData.firstName} ${patientData.lastName}`,
          FirstName: patientData.firstName,
          LastName: patientData.lastName,
          PreferredGender:
            patientData.gender ?
            patientData.gender : null,
          Sex: patientData.sex,
          Phone: patientData.phone || 'N/A',
          Email: patientData.email || 'N/A',
          SharedWith: {
            [firebase.auth().currentUser.uid]: true,
          },
        }
        firestore.collection('LCDS')
            .doc('Something')
            .collection('Organizations')
            .doc(organization)
            .collection('Patients')
            .doc(newRefKey)
            .set(newPatientData)
            .then(() => {
              const payload = {
                patientCreated: true,
                isLoading: false,
                // manually add patient to org patients
                orgPatientsObj: {
                  ...orgPatientsObj,
                  [newRefKey]: newPatientData,
                },
              }
              if (isTestRequest) {
                /*  adds formattedNewPatient to payload
                    This triggers sending the email to a patient
                    in useEffect hook of AddPatient.js
                */
                dispatch({
                  type: types.STORE_PAYLOAD,
                  payload: {
                    ...payload,
                    formattedNewPatient: newPatientData,
                  },
                  orgPatientList: [newPatientData, ...orgPatientList],
                })
              } else {
                dispatch({
                  type: types.STORE_PAYLOAD,
                  payload: {
                    ...payload,
                    responseMessage: 'New patient added',
                    userMessage: 'Success! New patient added',
                    notificationType: 2,
                    notificationIcon: 'check',
                  },
                })
              }
            })
      })
      .catch((error) => { // Dispatch failure message
        // console.log(error)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            patientCreated: false,
            isLoading: false,
            responseMessage:
              'There was an error adding a patient. Please try again later',
            userMessage: 'There was an error adding a patient. Please try again later',
            notificationType: 3,
            notificationIcon: 'warning',
          },
        })
      })
}
